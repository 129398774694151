import React, { useState } from "react";
import DropDown from "../Helpers/DropDown";

function MainSection() {
  const category1 = ["Web Developement", "UI/UX", "Programming", "reactjs"];
  const [selectedCategory, setCategory] = useState(category1[0]);
  const changeCategory1 = (value) => {
    setCategory(value);
  };
  return (
    <>
      <div className="binduz-er-map-area">
        <div className=" container">
          <div className="row">
            <div className=" col-lg-12">
              <div className="binduz-er-map-box">
                <iframe
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d41305.0575309695!2d90.40505299581727!3d23.78708119002918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1612844343331!5m2!1sen!2sbd"
                  width="100"
                  height="600"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  aria-hidden="false"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="binduz-er-contact-us-area">
        <div className=" container">
          <div className="row">
            <div className=" col-lg-12">
              <div className="binduz-er-contact-us-box">
                <form action="#">
                  <div className="binduz-er-contact-title">
                    <h4 className="binduz-er-title">
                      Get in touch & let us know
                    </h4>
                  </div>
                  <div className="row">
                    <div className=" col-lg-4">
                      <div className="binduz-er-input-box">
                        <input type="text" placeholder="Enter full name" />
                        <i className="fal fa-user"></i>
                      </div>
                    </div>
                    <div className=" col-lg-4">
                      <div className="binduz-er-input-box">
                        <input type="email" placeholder="Enter address" />
                        <i className="fal fa-envelope"></i>
                      </div>
                    </div>
                    <div className=" col-lg-4">
                      <div className="binduz-er-input-box binduz-er-select-item">
                        <DropDown
                          datas={category1}
                          selected={selectedCategory}
                          action={changeCategory1}
                          className="mexuvo-binduz-tech-dropdown"
                        />
                      </div>
                    </div>
                    <div className=" col-lg-12">
                      <div className="binduz-er-input-box">
                        <textarea
                          name="#"
                          id="#"
                          cols="30"
                          rows="10"
                          placeholder="Enter message"
                        ></textarea>
                        <i className="fal fa-pencil"></i>
                      </div>
                    </div>
                  </div>
                  <button type="button" className="binduz-er-main-btn">
                    Submit Request
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="row pt-90">
            <div className=" col-lg-4">
              <div className="binduz-er-contact-info-box">
                <h3 className="binduz-er-title">Corporate Office</h3>
                <ul>
                  <li>
                    Address: <span>Lorem 142 Str., 2352, Pro State, USA</span>
                  </li>
                  <li>
                    Phone: <span>+33-257634 534</span>
                  </li>
                  <li>
                    Email: <span>info@webexample.com</span>
                  </li>
                </ul>
                <a className="binduz-er-main-btn" href="#">
                  Make A Call
                </a>
              </div>
            </div>
            <div className=" col-lg-4">
              <div className="binduz-er-contact-info-box">
                <h3 className="binduz-er-title">Main HQ</h3>
                <ul>
                  <li>
                    Address: <span>Lorem 142 Str., 2352, Pro State, USA</span>
                  </li>
                  <li>
                    Phone: <span>+33-257634 534</span>
                  </li>
                  <li>
                    Email: <span>info@webexample.com</span>
                  </li>
                </ul>
                <a className="binduz-er-main-btn active" href="#">
                  Make A Call
                </a>
              </div>
            </div>
            <div className=" col-lg-4">
              <div className="binduz-er-contact-info-box">
                <h3 className="binduz-er-title">Advertise</h3>
                <ul>
                  <li>
                    Address: <span>Lorem 142 Str., 2352, Pro State, USA</span>
                  </li>
                  <li>
                    Phone: <span>+33-257634 534</span>
                  </li>
                  <li>
                    Email: <span>info@webexample.com</span>
                  </li>
                </ul>
                <a className="binduz-er-main-btn" href="#">
                  Make A Call
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainSection;

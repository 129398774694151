import React from "react";

function TopHeader({ drawerAction, searchAction }) {
  return (
    <div className="binduz-er-news-top-header-area-2 bg_cover">
      <div className=" container">
        <div className="row align-items-center">
          <div className=" col-lg-6 col-md-5">
            <div className="binduz-er-news-top-header-btns">
              <ul>
                <li>
                  <span
                    onClick={drawerAction}
                    className="binduz-er-toggle-btn binduz-er-news-canvas_open"
                  >
                    <i className="fal fa-bars"></i> Menu
                  </span>
                </li>
                <li>
                  <a
                    onClick={(e) => searchAction(e)}
                    className="binduz-er-news-search-open"
                    href="#"
                  >
                    <i className="fal fa-search"></i> Search
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className=" col-lg-6 col-md-7">
            <div className="binduz-er-news-top-header-weather">
              <ul>
                <li>
                  <a href="#">
                    <i className="fal fa-cloud"></i> 22°F
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fal fa-cloud"></i> 22°F
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fal fa-user"></i> Login/Sign
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopHeader;

import React from "react";

function Ads({ className }) {
  return (
    <div className={`binduz-er-footer-add pb-60 ${className || ""}`}>
      <div className=" container">
        <div className="row">
          <div className=" col-lg-12">
            <div className="binduz-er-footer-add-item text-center">
              <span className="mb-10 d-inline-block">ADVERTISEMENT</span>
              <img
                src={require(`../../assets/images/space-bg-4.jpg`).default}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ads;

import React, { useState } from "react";
import PostCardStyleOne from "../../containers/HomeOne/PostCards/PostCardStyleOne";

function VideoPostsSection({ category, videoPosts, className }) {
  const [tab, setTab] = useState(category[0].id);
  // tab handler
  const tabHandler = (e, value) => {
    e.preventDefault();
    setTab(value);
  };
  return (
    <section className={`binduz-er-video-post-area ${className || ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="binduz-er-video-post-topbar">
              <div className="binduz-er-video-post-title">
                <h3 className="binduz-er-title">Video Post</h3>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="binduz-er-video-post-tab">
              <ul
                className="nav nav-pills justify-content-lg-end justify-content-start"
                id="pills-tab-2"
                role="tablist"
              >
                {category &&
                  category.length > 0 &&
                  category.slice(0, 5).map((categoryItem) => (
                    <li
                      key={categoryItem.id + Math.random()}
                      className="nav-item"
                      role="presentation"
                    >
                      <a
                        onClick={(e) => tabHandler(e, categoryItem.id)}
                        className={`nav-link ${
                          categoryItem.id === tab ? "active" : ""
                        }`}
                        id="pills-21-tab"
                        data-bs-toggle="pill"
                        href="#pills-21"
                        role="tab"
                        aria-controls="pills-21"
                        aria-selected="true"
                      >
                        {categoryItem.name}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="tab-content" id="pills-tabContent-2">
          <div
            className="tab-pane fade show active"
            id="pills-21"
            role="tabpanel"
            aria-labelledby="pills-21-tab"
          >
            {category &&
              category.length > 0 &&
              category.slice(0, 5).map(
                (categoryItem) =>
                  categoryItem.id === tab && (
                    <div key={categoryItem.id + Math.random()} className="row">
                      <div className="col-lg-3 col-md-6 order-lg-1 order-1">
                        <div className="binduz-er-video-post-item">
                          {videoPosts &&
                            videoPosts.length > 0 &&
                            videoPosts
                              .slice(0, 2)
                              .map((videoPostItem, index) => (
                                <PostCardStyleOne
                                  key={videoPostItem.id + Math.random()}
                                  datas={{
                                    image:
                                      videoPostItem.post_images
                                        .video_posts_thumb[index],
                                    category: videoPostItem.post_cat[0],
                                    date: videoPostItem.post_date,
                                    title: videoPostItem.post_title,
                                  }}
                                />
                              ))}
                        </div>
                      </div>
                      <div className="col-lg-6 order-lg-2 order-3">
                        <div className="binduz-er-video-post-item">
                          <PostCardStyleOne
                            datas={{
                              image:
                                videoPosts[5].post_images.video_posts_thumb[2],
                              category: videoPosts[5].post_cat[0],
                              date: videoPosts[5].post_date,
                              title: videoPosts[5].post_title,
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 order-lg-3 order-2">
                        <div className="binduz-er-video-post-item">
                          {videoPosts &&
                            videoPosts.length > 0 &&
                            videoPosts
                              .slice(4, 6)
                              .map((videoPostItem, index) => (
                                <PostCardStyleOne
                                  key={videoPostItem.id + Math.random()}
                                  datas={{
                                    image:
                                      videoPostItem.post_images
                                        .video_posts_thumb[index],
                                    category: videoPostItem.post_cat[0],
                                    date: videoPostItem.post_date,
                                    title: videoPostItem.post_title,
                                  }}
                                />
                              ))}
                        </div>
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default VideoPostsSection;

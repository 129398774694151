import React from "react";
import navigationContext from "../../contexts/navigationsContext";
import { navigations } from "../../Data/navigation.json";
import { posts } from "../../Data/posts.json";
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../Helpers/BackToTop";
import Ads from "../HomeTwo/Ads";
import Drawer from "../Mobile/Drawer";
import Footer from "../Partials/Footers/HomeThree/index";
import HeaderThree from "../Partials/Headers/HeaderThree";
import DarkEditorPackSection from "./DarkEditorPackSection";
import EditorPackSection from "./EditorPackSection";
import FeaturePartSection from "./FeaturePartSection";
import HeroSliderSection from "./HeroSliderSection";
import PopularNewsSection from "./PopularNewsSection";

function HomeThree() {
  const navigationData = navigations;
  const editorPackSectionDatas = posts;
  const popularNewsData = posts;
  const [drawer, setDrawer] = useToggle(false);
  return (
    <>
      <Drawer
        drawer={drawer}
        action={setDrawer.toggle}
        navigationData={navigationData}
      />
      <navigationContext.Provider value={{ navigationData }}>
        <HeaderThree drawerAction={setDrawer.toggle} />
      </navigationContext.Provider>
      <HeroSliderSection />
      <Ads className="pb-60 pt-25" />

      <EditorPackSection editorPackSectionDatas={editorPackSectionDatas} />
      <FeaturePartSection />
      <DarkEditorPackSection editorPackSectionDatas={editorPackSectionDatas} />
      <PopularNewsSection popularNewsData={popularNewsData} />
      <Footer />
      <BackToTop />
    </>
  );
}

export default HomeThree;

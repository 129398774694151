import React from "react";

function Categories({ TreandingNewsCategoryTab = [] }) {
  return (
    <>
      <div className="binduz-er-sidebar-categories">
        <div className="binduz-er-sidebar-title">
          <h4 className="binduz-er-title">Categories</h4>
        </div>
        <div className="binduz-er-categories-list">
          {TreandingNewsCategoryTab &&
            TreandingNewsCategoryTab.length > 0 &&
            TreandingNewsCategoryTab.slice(1, 6).map((item) => (
              <div key={item.id + Math.random()} className="binduz-er-item">
                <a href="#">
                  <span>{item.name}</span>
                  <span className="binduz-er-number">02</span>
                </a>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default Categories;

import React from "react";
import Navigation from "../../../Helpers/Navigation";

function MainHeader({ content, className, drawerAction, searchAction }) {
  return (
    <>
      {content && content}
      <header className="binduz-er-header-area binduz-er-header-area-4">
        <div className="binduz-er-header-nav">
          <div className=" container">
            <div className="row">
              <div className=" col-lg-12">
                <div
                  className={`binduz-er-header-meddle-bar d-flex justify-content-between ${
                    className || ""
                  }`}
                >
                  <div className="binduz-er-logo">
                    <a href="/">
                      <img
                        src={
                          require(`../../../../assets/images/logo-6.png`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="navigation">
                    <nav className="navbar navbar-expand-lg">
                      <div
                        className="collapse navbar-collapse sub-menu-bar"
                        id="navbarSupportedContent"
                      >
                        <Navigation className="mr-auto" />
                      </div>
                      <div className="binduz-er-navbar-btn d-flex align-items-center">
                        <div className="binduz-er-search-btn d-none d-sm-block">
                          <a
                            onClick={(e) => searchAction(e)}
                            className="binduz-er-news-search-open"
                            href="#"
                          >
                            <i className="far fa-search"></i>
                          </a>
                        </div>
                        <span
                          onClick={drawerAction}
                          className="binduz-er-toggle-btn binduz-er-news-canvas_open d-block d-lg-none"
                        >
                          <i className="fal fa-bars"></i>
                        </span>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default MainHeader;

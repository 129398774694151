import React from "react";

function Author() {
  return (
    <section className="binduz-er-author-item-area pb-20">
      <div className=" container">
        <div className="row">
          <div className=" col-lg-9">
            <div className="binduz-er-author-item mb-40">
              <div className="binduz-er-thumb">
                <img
                  src={require(`../../assets/images/author-item-6.jpg`).default}
                  alt=""
                />
              </div>
              <div className="binduz-er-content">
                <div className="binduz-er-meta-item">
                  <div className="binduz-er-meta-categories">
                    <a href="#">Technology</a>
                  </div>
                  <div className="binduz-er-meta-date">
                    <span>
                      <i className="fal fa-calendar-alt"></i>24th February 2020
                    </span>
                  </div>
                </div>
                <h3 className="binduz-er-title">
                  <a href="#">
                    A new certification for health insurance advertisers in the
                    U.S.
                  </a>
                </h3>
                <div className="binduz-er-meta-author">
                  <div className="binduz-er-author">
                    <img
                      src={require(`../../assets/images/user-2.jpg`).default}
                      alt=""
                    />
                    <span>
                      By <span>Rosalina D.</span>
                    </span>
                  </div>
                  <div className="binduz-er-meta-list">
                    <ul>
                      <li>
                        <i className="fal fa-eye"></i> 5k
                      </li>
                      <li>
                        <i className="fal fa-heart"></i> 5k
                      </li>
                      <li>
                        <i className="fal fa-comments"></i> 5k
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="binduz-er-author-item mb-40">
              <div className="binduz-er-thumb">
                <img
                  src={require(`../../assets/images/author-item-5.jpg`).default}
                  alt=""
                />
              </div>
              <div className="binduz-er-content">
                <div className="binduz-er-meta-item">
                  <div className="binduz-er-meta-categories">
                    <a href="#">Technology</a>
                  </div>
                  <div className="binduz-er-meta-date">
                    <span>
                      <i className="fal fa-calendar-alt"></i>24th February 2020
                    </span>
                  </div>
                </div>
                <h3 className="binduz-er-title">
                  <a href="#">
                    Bring iconic Japanese characters to life with AR in Search
                  </a>
                </h3>
                <div className="binduz-er-meta-author">
                  <div className="binduz-er-author">
                    <img
                      src={require(`../../assets/images/user-2.jpg`).default}
                      alt=""
                    />
                    <span>
                      By <span>Rosalina D.</span>
                    </span>
                  </div>
                  <div className="binduz-er-meta-list">
                    <ul>
                      <li>
                        <i className="fal fa-eye"></i> 5k
                      </li>
                      <li>
                        <i className="fal fa-heart"></i> 5k
                      </li>
                      <li>
                        <i className="fal fa-comments"></i> 5k
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="binduz-er-author-item mb-40">
              <div className="binduz-er-thumb">
                <img
                  src={require(`../../assets/images/author-item-4.jpg`).default}
                  alt=""
                />
              </div>
              <div className="binduz-er-content">
                <div className="binduz-er-meta-item">
                  <div className="binduz-er-meta-categories">
                    <a href="#">Technology</a>
                  </div>
                  <div className="binduz-er-meta-date">
                    <span>
                      <i className="fal fa-calendar-alt"></i>24th February 2020
                    </span>
                  </div>
                </div>
                <h3 className="binduz-er-title">
                  <a href="#">
                    How to pot you web app to microsoft clone teams is really
                    ready for take fight.{" "}
                  </a>
                </h3>
                <div className="binduz-er-meta-author">
                  <div className="binduz-er-author">
                    <img
                      src={require(`../../assets/images/user-2.jpg`).default}
                      alt=""
                    />
                    <span>
                      By <span>Rosalina D.</span>
                    </span>
                  </div>
                  <div className="binduz-er-meta-list">
                    <ul>
                      <li>
                        <i className="fal fa-eye"></i> 5k
                      </li>
                      <li>
                        <i className="fal fa-heart"></i> 5k
                      </li>
                      <li>
                        <i className="fal fa-comments"></i> 5k
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="binduz-er-author-item mb-40">
              <div className="binduz-er-thumb">
                <img
                  src={require(`../../assets/images/author-item-3.jpg`).default}
                  alt=""
                />
              </div>
              <div className="binduz-er-content">
                <div className="binduz-er-meta-item">
                  <div className="binduz-er-meta-categories">
                    <a href="#">Technology</a>
                  </div>
                  <div className="binduz-er-meta-date">
                    <span>
                      <i className="fal fa-calendar-alt"></i>24th February 2020
                    </span>
                  </div>
                </div>
                <h3 className="binduz-er-title">
                  <a href="#">
                    When (and why) we remove content from Google search results
                  </a>
                </h3>
                <div className="binduz-er-meta-author">
                  <div className="binduz-er-author">
                    <img
                      src={require(`../../assets/images/user-2.jpg`).default}
                      alt=""
                    />
                    <span>
                      By <span>Rosalina D.</span>
                    </span>
                  </div>
                  <div className="binduz-er-meta-list">
                    <ul>
                      <li>
                        <i className="fal fa-eye"></i> 5k
                      </li>
                      <li>
                        <i className="fal fa-heart"></i> 5k
                      </li>
                      <li>
                        <i className="fal fa-comments"></i> 5k
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="binduz-er-author-item mb-40">
              <div className="binduz-er-thumb">
                <img
                  src={require(`../../assets/images/author-item-2.jpg`).default}
                  alt=""
                />
              </div>
              <div className="binduz-er-content">
                <div className="binduz-er-meta-item">
                  <div className="binduz-er-meta-categories">
                    <a href="#">Technology</a>
                  </div>
                  <div className="binduz-er-meta-date">
                    <span>
                      <i className="fal fa-calendar-alt"></i>24th February 2020
                    </span>
                  </div>
                </div>
                <h3 className="binduz-er-title">
                  <a href="#">
                    El tiempo vuela en la mayor actualización de Google Earth en
                    años
                  </a>
                </h3>
                <div className="binduz-er-meta-author">
                  <div className="binduz-er-author">
                    <img
                      src={require(`../../assets/images/user-2.jpg`).default}
                      alt=""
                    />
                    <span>
                      By <span>Rosalina D.</span>
                    </span>
                  </div>
                  <div className="binduz-er-meta-list">
                    <ul>
                      <li>
                        <i className="fal fa-eye"></i> 5k
                      </li>
                      <li>
                        <i className="fal fa-heart"></i> 5k
                      </li>
                      <li>
                        <i className="fal fa-comments"></i> 5k
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="binduz-er-author-item mb-40">
              <div className="binduz-er-thumb">
                <img
                  src={require(`../../assets/images/author-item-1.jpg`).default}
                  alt=""
                />
              </div>
              <div className="binduz-er-content">
                <div className="binduz-er-meta-item">
                  <div className="binduz-er-meta-categories">
                    <a href="#">Technology</a>
                  </div>
                  <div className="binduz-er-meta-date">
                    <span>
                      <i className="fal fa-calendar-alt"></i>24th February 2020
                    </span>
                  </div>
                </div>
                <h3 className="binduz-er-title">
                  <a href="#">
                    Conversion modeling through Consent Mode in Google Ads
                  </a>
                </h3>
                <div className="binduz-er-meta-author">
                  <div className="binduz-er-author">
                    <img
                      src={require(`../../assets/images/user-2.jpg`).default}
                      alt=""
                    />
                    <span>
                      By <span>Rosalina D.</span>
                    </span>
                  </div>
                  <div className="binduz-er-meta-list">
                    <ul>
                      <li>
                        <i className="fal fa-eye"></i> 5k
                      </li>
                      <li>
                        <i className="fal fa-heart"></i> 5k
                      </li>
                      <li>
                        <i className="fal fa-comments"></i> 5k
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-lg-3">
            <div className="binduz-er-populer-news-sidebar">
              <div className="binduz-er-archived-sidebar-about">
                <div className="binduz-er-user">
                  <img
                    src={
                      require(`../../assets/images/archived-about.png`).default
                    }
                    alt=""
                  />
                  <div className="binduz-er-icon">
                    <i className="fal fa-newspaper"></i>
                  </div>
                </div>
                <span>Senior Reportar</span>
                <h4 className="binduz-er-title">Miranda H. Hilixer</h4>
                <ul>
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-behance"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="binduz-er-author-sidebar-search-bar">
                <form action="#">
                  <div className="binduz-er-input-box">
                    <input type="text" placeholder="Search from here..." />
                    <button type="button">
                      <i className="fal fa-search"></i>
                    </button>
                  </div>
                </form>
              </div>

              <div className="binduz-er-populer-news-sidebar-post pt-40">
                <div className="binduz-er-popular-news-title">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab-2"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        href="#pills-home"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Most Popular
                      </a>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        href="#pills-profile"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Most Recent
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div className="tab-content" id="pills-tabContent-2">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="binduz-er-sidebar-latest-post-box">
                      <div className="binduz-er-sidebar-latest-post-item">
                        <div className="binduz-er-thumb">
                          <img
                            src={
                              require(`../../assets/images/latest-post-1.jpg`)
                                .default
                            }
                            alt="latest"
                          />
                        </div>
                        <div className="binduz-er-content">
                          <span>
                            <i className="fal fa-calendar-alt"></i> 24th
                            February 2020
                          </span>
                          <h4 className="binduz-er-title">
                            <a href="#">
                              Why creating inclusive classrooms matters
                            </a>
                          </h4>
                        </div>
                      </div>
                      <div className="binduz-er-sidebar-latest-post-item">
                        <div className="binduz-er-thumb">
                          <img
                            src={
                              require(`../../assets/images/latest-post-2.jpg`)
                                .default
                            }
                            alt="latest"
                          />
                        </div>
                        <div className="binduz-er-content">
                          <span>
                            <i className="fal fa-calendar-alt"></i> 24th
                            February 2020
                          </span>
                          <h4 className="binduz-er-title">
                            <a href="#">
                              Celebrating Asian Pacific American art and
                            </a>
                          </h4>
                        </div>
                      </div>
                      <div className="binduz-er-sidebar-latest-post-item">
                        <div className="binduz-er-thumb">
                          <img
                            src={
                              require(`../../assets/images/latest-post-3.jpg`)
                                .default
                            }
                            alt="latest"
                          />
                        </div>
                        <div className="binduz-er-content">
                          <span>
                            <i className="fal fa-calendar-alt"></i> 24th
                            February 2020
                          </span>
                          <h4 className="binduz-er-title">
                            <a href="#">
                              From overcoming burnout to finding new
                            </a>
                          </h4>
                        </div>
                      </div>
                      <div className="binduz-er-sidebar-latest-post-item">
                        <div className="binduz-er-thumb">
                          <img
                            src={
                              require(`../../assets/images/latest-post-4.jpg`)
                                .default
                            }
                            alt="latest"
                          />
                        </div>
                        <div className="binduz-er-content">
                          <span>
                            <i className="fal fa-calendar-alt"></i> 24th
                            February 2020
                          </span>
                          <h4 className="binduz-er-title">
                            <a href="#">
                              Sparks of inspiration to the new trend 2021
                            </a>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="binduz-er-sidebar-latest-post-box">
                      <div className="binduz-er-sidebar-latest-post-item">
                        <div className="binduz-er-thumb">
                          <img
                            src={
                              require(`../../assets/images/latest-post-1.jpg`)
                                .default
                            }
                            alt="latest"
                          />
                        </div>
                        <div className="binduz-er-content">
                          <span>
                            <i className="fal fa-calendar-alt"></i> 24th
                            February 2020
                          </span>
                          <h4 className="binduz-er-title">
                            <a href="#">
                              Why creating inclusive classrooms matters
                            </a>
                          </h4>
                        </div>
                      </div>
                      <div className="binduz-er-sidebar-latest-post-item">
                        <div className="binduz-er-thumb">
                          <img
                            src={
                              require(`../../assets/images/latest-post-2.jpg`)
                                .default
                            }
                            alt="latest"
                          />
                        </div>
                        <div className="binduz-er-content">
                          <span>
                            <i className="fal fa-calendar-alt"></i> 24th
                            February 2020
                          </span>
                          <h4 className="binduz-er-title">
                            <a href="#">
                              Celebrating Asian Pacific American art and
                            </a>
                          </h4>
                        </div>
                      </div>
                      <div className="binduz-er-sidebar-latest-post-item">
                        <div className="binduz-er-thumb">
                          <img
                            src={
                              require(`../../assets/images/latest-post-3.jpg`)
                                .default
                            }
                            alt="latest"
                          />
                        </div>
                        <div className="binduz-er-content">
                          <span>
                            <i className="fal fa-calendar-alt"></i> 24th
                            February 2020
                          </span>
                          <h4 className="binduz-er-title">
                            <a href="#">
                              From overcoming burnout to finding new
                            </a>
                          </h4>
                        </div>
                      </div>
                      <div className="binduz-er-sidebar-latest-post-item">
                        <div className="binduz-er-thumb">
                          <img
                            src={
                              require(`../../assets/images/latest-post-4.jpg`)
                                .default
                            }
                            alt="latest"
                          />
                        </div>
                        <div className="binduz-er-content">
                          <span>
                            <i className="fal fa-calendar-alt"></i> 24th
                            February 2020
                          </span>
                          <h4 className="binduz-er-title">
                            <a href="#">
                              Sparks of inspiration to the new trend 2021
                            </a>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="binduz-er-populer-news-sidebar-newsletter binduz-er-author-page-newsletter mt-40">
                <div className="binduz-er-newsletter-box text-center">
                  <img
                    src={require(`../../assets/images/icon/icon-3.png`).default}
                    alt=""
                  />
                  <h3 className="binduz-er-title">Get Every Newsletter</h3>
                  <p>We are not gonna make spamming</p>
                  <div className="binduz-er-input-box">
                    <input type="text" placeholder="Enter your email" />
                    <button type="button">
                      <i className="fal fa-long-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="binduz-er-populer-news-social binduz-er-author-page-social mt-40">
                <div className="binduz-er-popular-news-title">
                  <h3 className="binduz-er-title">Social Connects</h3>
                </div>
                <div className="binduz-er-social-list">
                  <div className="binduz-er-list">
                    <a href="#">
                      <span>
                        <i className="fab fa-facebook-f"></i> <span>15000</span>{" "}
                        Likes
                      </span>
                      <span>Like</span>
                    </a>
                    <a href="#">
                      <span>
                        <i className="fab fa-twitter"></i> <span>15000</span>{" "}
                        Likes
                      </span>
                      <span>Tweet</span>
                    </a>
                    <a href="#">
                      <span>
                        <i className="fab fa-behance"></i> <span>5k+</span>{" "}
                        Follower
                      </span>
                      <span>Follow</span>
                    </a>
                    <a href="#">
                      <span>
                        <i className="fab fa-youtube"></i> <span>15000</span>{" "}
                        Subscribe
                      </span>
                      <span>Subscribe</span>
                    </a>
                  </div>
                </div>
              </div>

              <div className="binduz-er-populer-news-social binduz-er-author-page-social mt-40">
                <div className="binduz-er-popular-news-title">
                  <h3 className="binduz-er-title">Video Post</h3>
                </div>
                <div className="binduz-er-video-post binduz-er-recently-viewed-item">
                  <div className="binduz-er-latest-news-item">
                    <div className="binduz-er-thumb">
                      <img
                        src="assets/images/editors-pack-thumb-1.jpg"
                        alt=""
                      />
                      <div className="binduz-er-play">
                        <a href="#">
                          <i className="fas fa-play"></i>
                        </a>
                      </div>
                    </div>
                    <div className="binduz-er-content">
                      <div className="binduz-er-meta-item">
                        <div className="binduz-er-meta-categories">
                          <a href="#">Technology</a>
                        </div>
                        <div className="binduz-er-meta-date">
                          <span>
                            <i className="fal fa-calendar-alt"></i>24th February
                            2020
                          </span>
                        </div>
                      </div>
                      <h5 className="binduz-er-title">
                        <a href="#">
                          Nearly three weeks after Rita Ora and Chris Brown
                          released their collaboration, “Body On Me,”
                        </a>
                      </h5>
                      <div className="binduz-er-meta-author">
                        <span>
                          By <span>Rosalina D.</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="binduz-er-sidebar-social binduz-er-populer-news-sidebar-add d-none d-lg-block">
                <div className="binduz-er-sidebar-add mt-40">
                  <h3 className="binduz-er-title">
                    Build your website & <span>grow your business</span>
                  </h3>
                  <a className="binduz-er-main-btn" href="#">
                    Purchase
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Author;

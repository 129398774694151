import React, { useState } from "react";

function NewsCategories({ categories }) {
  const [tab, setTab] = useState(categories[0].id);
  const tabHandler = (e, value) => {
    e.preventDefault();
    setTab(value);
  };
  return (
    <section className="binduz-er-news-categories-area">
      <div className=" container">
        <div className="row">
          <div className=" col-lg-12">
            <div className="binduz-er-news-categories-tab">
              <ul
                className="nav nav-pills justify-content-center"
                id="pills-tab"
                role="tablist"
              >
                {categories &&
                  categories.length > 0 &&
                  categories.map((catItem) => (
                    <li
                      key={catItem.id}
                      className="nav-item"
                      role="presentation"
                    >
                      <a
                        onClick={(e) => tabHandler(e, catItem.id)}
                        className={`nav-link ${
                          tab === catItem.id ? "active" : ""
                        }`}
                        id="pills-1-tab"
                        data-bs-toggle="pill"
                        href="#pills-1"
                        role="tab"
                        aria-controls="pills-1"
                        aria-selected="true"
                      >
                        {catItem.name}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="tab-content" id="pills-tabContent">
              {categories &&
                categories.length > 0 &&
                categories.map((catItem) => (
                  <div
                    key={catItem.id}
                    className={`tab-pane fade ${
                      tab === catItem.id ? "show active" : ""
                    }`}
                    id="pills-1"
                    role="tabpanel"
                    aria-labelledby="pills-1-tab"
                  >
                    <div className="binduz-er-news-categories-box">
                      <div className="row">
                        <div className=" col-lg-4">
                          <div className="binduz-er-news-categories-item">
                            <div className="binduz-er-thumb">
                              <img
                                src={
                                  require(`../../assets/images/categories-news-1.jpg`)
                                    .default
                                }
                                alt=""
                              />
                            </div>
                            <div className="binduz-er-content">
                              <div className="binduz-er-meta-date">
                                <span>
                                  <i className="fal fa-calendar-alt"></i> 24th
                                  February 2020
                                </span>
                              </div>
                              <div className="binduz-er-news-categories-title">
                                <h4 className="binduz-er-title">
                                  <a href="#">
                                    Five ways we’re making Google the safer way
                                  </a>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" col-lg-4">
                          <div className="binduz-er-news-categories-item">
                            <div className="binduz-er-thumb">
                              <img
                                src={
                                  require(`../../assets/images/categories-news-2.jpg`)
                                    .default
                                }
                                alt=""
                              />
                            </div>
                            <div className="binduz-er-content">
                              <div className="binduz-er-meta-date">
                                <span>
                                  <i className="fal fa-calendar-alt"></i> 24th
                                  February 2020
                                </span>
                              </div>
                              <div className="binduz-er-news-categories-title">
                                <h4 className="binduz-er-title">
                                  <a href="#">
                                    Bring iconic Japanese characters to life
                                    with
                                  </a>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" col-lg-4">
                          <div className="binduz-er-news-categories-item">
                            <div className="binduz-er-thumb">
                              <img
                                src={
                                  require(`../../assets/images/categories-news-3.jpg`)
                                    .default
                                }
                                alt=""
                              />
                            </div>
                            <div className="binduz-er-content">
                              <div className="binduz-er-meta-date">
                                <span>
                                  <i className="fal fa-calendar-alt"></i> 24th
                                  February 2020
                                </span>
                              </div>
                              <div className="binduz-er-news-categories-title">
                                <h4 className="binduz-er-title">
                                  <a href="#">
                                    Get ready for Hollywood’s big night with
                                    Google
                                  </a>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewsCategories;

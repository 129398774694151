import React from "react";
import { Link } from "react-router-dom";
import dateFormat from "../../../lib/dateFormat";

function CardRowStyleHomeTwo({
  datas = { image: "", category: "", title: "", date: "" },
}) {
  return (
    <div className="binduz-er-latest-news-item">
      <div className="binduz-er-thumb">
        <img
          src={require(`../../../assets/images/${datas.image}`).default}
          alt=""
        />
      </div>
      <div className="binduz-er-content">
        <div className="binduz-er-meta-categories">
          <a href="#">{datas.category}</a>
        </div>
        <h5 className="binduz-er-title line-clump-2">
          <Link to="/blog-details-two">{datas.title}</Link>
        </h5>
        <div className="binduz-er-meta-item">
          <div className="binduz-er-meta-author">
            <span>
              By <span>Miranda H. Halim</span>
            </span>
          </div>
          <div className="binduz-er-meta-date">
            <span>
              <i className="fal fa-calendar-alt"></i> {dateFormat(datas.date)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardRowStyleHomeTwo;

import React from "react";

function TopNews() {
  return (
    <>
      <div className="binduz-er-top-news-title">
        <h3 className="binduz-er-title">Top News</h3>
      </div>
      <div className="binduz-er-top-news-item">
        <span>01</span>
        <h5 className="binduz-er-title">
          <a href="#">
            How you'll find accurate and timely information on COVID-19 vaccines
            How you'll find accurate and timely
          </a>
        </h5>
        <div className="binduz-er-meta-date">
          <span>
            <i className="fal fa-calendar-alt"></i> 24th February 2020
          </span>
        </div>
      </div>
      <div className="binduz-er-top-news-item">
        <span>02</span>
        <h5 className="binduz-er-title">
          <a href="#">
            New Cook County Circuit Court clerk want to leave her predecessor’s
            era behind, in on updating the nation’s secondt.
          </a>
        </h5>
        <div className="binduz-er-meta-date">
          <span>
            <i className="fal fa-calendar-alt"></i> 24th February 2020
          </span>
        </div>
      </div>
      <div className="binduz-er-top-news-item">
        <span>03</span>
        <h5 className="binduz-er-title">
          <a href="#">
            Organizing the world’s information: where does it all come from
            Organizing the world’s information
          </a>
        </h5>
        <div className="binduz-er-meta-date">
          <span>
            <i className="fal fa-calendar-alt"></i> 24th February 2020
          </span>
        </div>
      </div>
    </>
  );
}

export default TopNews;

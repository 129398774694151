import React from "react";
import CardStyleHomeTwo from "../../containers/HomeTwo/Cards/CardStyleHomeTwo";

function RecentlyViewedSection({ recentlyViewedDatas = [] }) {
  return (
    <section className="binduz-er-recently-viewed-area">
      <div className=" container">
        <div className="binduz-er-recently-viewed-box">
          <div className="row">
            <div className=" col-lg-12">
              <div className="binduz-er-top-news-title">
                <h3 className="binduz-er-title">Recently Viewed</h3>
              </div>
            </div>
          </div>
          <div className="row">
            {recentlyViewedDatas &&
              recentlyViewedDatas.length > 0 &&
              recentlyViewedDatas.slice(0, 4).map((recentlyItem, index) => (
                <div key={recentlyItem.id} className=" col-lg-3">
                  <div className="binduz-er-video-post binduz-er-recently-viewed-item">
                    <CardStyleHomeTwo
                      datas={{
                        image: recentlyItem.post_images.recent_views[index],
                        category: recentlyItem.post_cat[0],
                        title: recentlyItem.post_title,
                        date: recentlyItem.post_date,
                      }}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default RecentlyViewedSection;

import React, { useContext } from "react";
import MainPostsContext from "../../contexts/HomeOne/MainPostsContext";
import PostRowCardStyleOne from "./PostCards/PostRowCardStyleOne";

function SidebarLatestPosts() {
  const { mainPosts } = useContext(MainPostsContext);
  return (
    <div className="binduz-er-sidebar-latest-post">
      <div className="binduz-er-sidebar-title">
        <h4 className="binduz-er-title">Latest Post</h4>
      </div>
      <div className="binduz-er-sidebar-latest-post-box">
        {mainPosts &&
          mainPosts.length > 0 &&
          mainPosts.slice(0, 4).map((mainPostItems, index) => (
            <PostRowCardStyleOne
              key={mainPostItems.id + Math.random()}
              datas={{
                image: mainPostItems.post_images.latest_posts[index],
                date: mainPostItems.post_date,
                title: mainPostItems.post_title,
              }}
            />
          ))}
      </div>
    </div>
  );
}

export default SidebarLatestPosts;

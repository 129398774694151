import React from "react";
import PostRowCardStyleOneHomeFour from "../../containers/HomeFour/Cards/PostRowCardStyleOneHomeFour";
import TrendingNews from "../../containers/HomeFour/TrendingNews";
import PostRowCardStyleOne from "../../containers/HomeOne/PostCards/PostRowCardStyleOne";

function MainContent({ postsForMainContent, className }) {
  return (
    <section className={`binduz-er-video-post-4-area ${className || ""}`}>
      <div className=" container">
        <div className="row">
          <div className=" col-lg-9">
            <div className="binduz-er-recent-news-box">
              <div className="binduz-er-recent-news-title">
                <h3 className="binduz-er-title">Recent News</h3>
              </div>
              {postsForMainContent &&
                postsForMainContent.length > 0 &&
                postsForMainContent
                  .slice(0, 4)
                  .map((postsForMainContentItem, index) => (
                    <PostRowCardStyleOneHomeFour
                      key={postsForMainContentItem.id}
                      datas={{
                        image:
                          postsForMainContentItem.post_images.latest_news[
                            index
                          ],
                        title: postsForMainContentItem.title,
                        date: postsForMainContentItem.post_date,
                        description: postsForMainContentItem.post_content,
                        category: postsForMainContentItem.post_cat[0],
                      }}
                    />
                  ))}

              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">
                        <i className="fal fa-long-arrow-left"></i>
                      </span>
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="binduz-er-page-link" href="#">
                      01
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="binduz-er-page-link" href="#">
                      02
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="binduz-er-page-link" href="#">
                      ---
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="binduz-er-page-link" href="#">
                      10
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">
                        <i className="fal fa-long-arrow-right"></i>
                      </span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="row mt-10">
              <div className=" col-lg-4">
                <div className="binduz-er-video-post-box">
                  <div className="binduz-er-video-post-title">
                    <h3 className="binduz-er-title">Video Post</h3>
                  </div>
                  <div className="binduz-er-video-post">
                    <div className="binduz-er-latest-news-item">
                      <div className="binduz-er-thumb">
                        <img
                          src={
                            require(`../../assets/images/video-post-1.jpg`)
                              .default
                          }
                          alt=""
                        />
                        <div className="binduz-er-play">
                          <a className="binduz-er-video-popup" href="#">
                            <i className="fas fa-play"></i>
                          </a>
                        </div>
                      </div>
                      <div className="binduz-er-content">
                        <div className="binduz-er-meta-item">
                          <div className="binduz-er-meta-date">
                            <span>
                              <i className="fal fa-calendar-alt"></i>24th
                              February 2020
                            </span>
                          </div>
                        </div>
                        <h5 className="binduz-er-title">
                          <a href="#">
                            So...what does a technical writer actually
                          </a>
                        </h5>
                      </div>
                    </div>
                    <div className="binduz-er-latest-news-item">
                      <div className="binduz-er-thumb">
                        <img
                          src={
                            require(`../../assets/images/video-post-2.jpg`)
                              .default
                          }
                          alt=""
                        />
                        <div className="binduz-er-play">
                          <a className="binduz-er-video-popup" href="#">
                            <i className="fas fa-play"></i>
                          </a>
                        </div>
                      </div>
                      <div className="binduz-er-content">
                        <div className="binduz-er-meta-item">
                          <div className="binduz-er-meta-date">
                            <span>
                              <i className="fal fa-calendar-alt"></i>24th
                              February 2020
                            </span>
                          </div>
                        </div>
                        <h5 className="binduz-er-title">
                          <a href="#">
                            Sping Fashion Show snow of Michigan rotation.
                          </a>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-lg-8">
                <TrendingNews />
              </div>
            </div>
            <div className="binduz-er-footer-add pt-35">
              <div className="binduz-er-footer-add-item text-center">
                <span className="mb-10 d-inline-block">ADVERTISEMENT</span>
                <img
                  src={require(`../../assets/images/space-bg-4.jpg`).default}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className=" col-lg-3">
            <div className="binduz-er-video-post-sidebar">
              <div className="binduz-er-social-box">
                <div className="binduz-er-social-title">
                  <h4 className="binduz-er-title">Social Connects</h4>
                </div>
                <div className="binduz-er-social-list">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i> <span>15000</span>
                        Likes
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i> <span>15000</span>
                        Likes
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-behance"></i> <span>5k+</span>
                        Follower
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-youtube"></i> <span>1M+</span>
                        Subscriber
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="home-four-binduz-popular-post binduz-er-populer-news-sidebar-post pt-30 binduz-er-most-populer-post-box">
                <div className="binduz-er-popular-news-title">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab-2"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        href="#pills-home"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Most Popular
                      </a>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        href="#pills-profile"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Most Recent
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div className="tab-content" id="pills-tabContent-3">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="binduz-er-sidebar-latest-post-box">
                      {postsForMainContent &&
                        postsForMainContent.length > 0 &&
                        postsForMainContent
                          .slice(0, 3)
                          .map((postsItem, index) => (
                            <PostRowCardStyleOne
                              key={postsItem.id}
                              datas={{
                                image:
                                  postsItem.post_images.latest_posts[index],
                                date: postsItem.post_date,
                                title: postsItem.post_title,
                              }}
                            />
                          ))}
                    </div>
                  </div>
                  {/* <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="binduz-er-sidebar-latest-post-box">
                      <div className="binduz-er-sidebar-latest-post-item">
                        <div className="binduz-er-thumb">
                          <img
                            src={
                              require(`../../assets/images/latest-post-1.jpg`)
                                .default
                            }
                            alt="latest"
                          />
                        </div>
                        <div className="binduz-er-content">
                          <span>
                            <i className="fal fa-calendar-alt"></i> 24th
                            February 2020
                          </span>
                          <h4 className="binduz-er-title">
                            <a href="#">
                              Sparks of inspiration to the new trend 2021
                            </a>
                          </h4>
                        </div>
                      </div>
                      <div className="binduz-er-sidebar-latest-post-item">
                        <div className="binduz-er-thumb">
                          <img
                            src={
                              require(`../../assets/images/latest-post-2.jpg`)
                                .default
                            }
                            alt="latest"
                          />
                        </div>
                        <div className="binduz-er-content">
                          <span>
                            <i className="fal fa-calendar-alt"></i> 24th
                            February 2020
                          </span>
                          <h4 className="binduz-er-title">
                            <a href="#">
                              Sparks of inspiration to the new trend 2021
                            </a>
                          </h4>
                        </div>
                      </div>
                      <div className="binduz-er-sidebar-latest-post-item">
                        <div className="binduz-er-thumb">
                          <img
                            src={
                              require(`../../assets/images/latest-post-3.jpg`)
                                .default
                            }
                            alt="latest"
                          />
                        </div>
                        <div className="binduz-er-content">
                          <span>
                            <i className="fal fa-calendar-alt"></i> 24th
                            February 2020
                          </span>
                          <h4 className="binduz-er-title">
                            <a href="#">
                              Sparks of inspiration to the new trend 2021
                            </a>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="binduz-er-add-box mt-40">
                <img
                  src={require(`../../assets/images/add-thumb-2.jpg`).default}
                  alt=""
                />
              </div>
              <div className="binduz-er-populer-news-sidebar-newsletter mt-40">
                <div className="binduz-er-newsletter-box text-center">
                  <img
                    src={require(`../../assets/images/icon/icon-3.png`).default}
                    alt=""
                  />
                  <h3 className="binduz-er-title">Get Every Newsletter</h3>
                  <p>We are not gonna make spamming</p>
                  <div className="binduz-er-input-box">
                    <input type="text" placeholder="Enter your email" />
                    <button type="button">
                      <i className="fal fa-long-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="binduz-er-sidebar-social binduz-er-populer-news-sidebar-add d-none d-lg-block">
                <div className="binduz-er-sidebar-add mt-40">
                  <h3 className="binduz-er-title">
                    Build your website & <span>grow your business</span>
                  </h3>
                  <a className="binduz-er-main-btn" href="#">
                    Purchase
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainContent;

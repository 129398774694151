import React, { useState } from "react";
import PostCardStyleOneHomeThree from "../../containers/HomeThree/Cards/PostCardStyleOneHomeThree";

function TrendingNewsSection({ trendingNewsDatas, className }) {
  const trendingNewsCategories = [
    {
      id: 1,
      name: "All",
    },
    {
      id: 2,
      name: "Travel",
    },
    {
      id: 3,
      name: "Fashion",
    },
    {
      id: 4,
      name: "Health",
    },
    {
      id: 5,
      name: "Nature",
    },
  ];
  const [tab, setTab] = useState(trendingNewsCategories[0].id);
  const tabHandler = (e, value) => {
    e.preventDefault();
    setTab(value);
  };
  return (
    <section className={`binduz-er-trending-news-box-area ${className || ""}`}>
      <div className=" container">
        <div className="row">
          <div className=" col-lg-12">
            <div className="binduz-er-trending-news-box">
              <div className="binduz-er-trending-news-topbar d-block d-md-flex justify-content-between align-items-center">
                <h3 className="binduz-er-title">Trending News</h3>
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  {trendingNewsCategories &&
                    trendingNewsCategories.length > 0 &&
                    trendingNewsCategories.map((trendingNewsCategoryItem) => (
                      <li
                        key={trendingNewsCategoryItem.id}
                        className="nav-item"
                        role="presentation"
                      >
                        <a
                          className={`nav-link ${
                            tab === trendingNewsCategoryItem.id ? "active" : ""
                          }`}
                          onClick={(e) =>
                            tabHandler(e, trendingNewsCategoryItem.id)
                          }
                          id="pills-1-tab"
                          data-bs-toggle="pill"
                          href="#pills-1"
                          role="tab"
                          aria-controls="pills-1"
                          aria-selected="true"
                        >
                          {trendingNewsCategoryItem.name}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="tab-content" id="pills-tabContent">
                {trendingNewsCategories &&
                  trendingNewsCategories.length > 0 &&
                  trendingNewsCategories.map((trendingNewsCategoryItem) => (
                    <div
                      key={trendingNewsCategoryItem.id}
                      className={`tab-pane fade ${
                        tab === trendingNewsCategoryItem.id ? "show active" : ""
                      }`}
                      id="pills-1"
                      role="tabpanel"
                      aria-labelledby="pills-1-tab"
                    >
                      <div className="row">
                        {trendingNewsDatas &&
                          trendingNewsDatas.length > 0 &&
                          trendingNewsDatas
                            .slice(0, 4)
                            .map((trendingNewsItems, index) => (
                              <div
                                key={trendingNewsItems.id}
                                className=" col-lg-3 col-md-6"
                              >
                                <PostCardStyleOneHomeThree
                                  datas={{
                                    image:
                                      trendingNewsItems.post_images
                                        .trending_today[index],
                                    category: trendingNewsItems.post_cat[0],
                                    date: trendingNewsItems.post_date,
                                    title: trendingNewsItems.post_title,
                                  }}
                                />
                              </div>
                            ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TrendingNewsSection;

import React, { useContext } from "react";
import mainPostsData from "../../contexts/HomeOne/MainPostsContext";
import PostCardStyleOne from "./PostCards/PostCardStyleOne";

function MainPosts() {
  const { mainPosts } = useContext(mainPostsData);
  return (
    <div className="row">
      {mainPosts &&
        mainPosts.length > 0 &&
        mainPosts.slice(16, 22).map((mainPostsItem, index) => (
          <div
            key={mainPostsItem.id + Math.random()}
            className="col-xl-4 col-lg-6 col-md-6"
          >
            <div className="binduz-er-main-posts-item">
              <PostCardStyleOne
                datas={{
                  image: mainPostsItem.post_images.main_posts[index],
                  category: mainPostsItem.post_cat[0],
                  date: mainPostsItem.post_date,
                  title: mainPostsItem.post_title,
                }}
              >
                <p className="line-clump-3">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor in cididunt ut labore et dolore
                </p>
              </PostCardStyleOne>
            </div>
          </div>
        ))}
    </div>
  );
}

export default MainPosts;

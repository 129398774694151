import React from "react";

function SocialFeed() {
  return (
    <>
      <div className="binduz-er-sidebar-social">
        <div className="binduz-er-sidebar-title">
          <h4 className="binduz-er-title">Social Feed</h4>
        </div>
        <div className="binduz-er-social-list">
          <div className="binduz-er-list">
            <a href="#">
              <span>
                <i className="fab fa-facebook-f"></i> <span>15000</span>
                Likes
              </span>
              <span>Like</span>
            </a>
            <a href="#">
              <span>
                <i className="fab fa-twitter"></i> <span>15000</span>
                Likes
              </span>
              <span>Tweet</span>
            </a>
            <a href="#">
              <span>
                <i className="fab fa-behance"></i> <span>5k+</span>
                Follower
              </span>
              <span>Follow</span>
            </a>
            <a href="#">
              <span>
                <i className="fab fa-youtube"></i> <span>15000</span>
                Subscribe
              </span>
              <span>Subscribe</span>
            </a>
          </div>
        </div>
        <div className="binduz-er-sidebar-add mt-20">
          <h3 className="binduz-er-title">
            Build your website & <span>grow your business</span>
          </h3>
          <a className="binduz-er-main-btn" href="#">
            Purchase
          </a>
        </div>
      </div>
    </>
  );
}

export default SocialFeed;

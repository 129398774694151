import React from "react";

function Footer() {
  return (
    <>
      <footer className="binduz-er-footer-2-area">
        <div className=" container">
          <div className="row">
            <div className=" col-lg-12">
              <div className="binduz-er-footer-box">
                <div className="row">
                  <div className=" col-lg-4">
                    <div className="binduz-er-footer-about text-center">
                      <div className="binduz-er-logo">
                        <a href="/">
                          <img
                            src={
                              require(`../../../../assets/images/logo-4.png`)
                                .default
                            }
                            alt=""
                          />
                        </a>
                      </div>
                      <p>
                        Michael Madigan on Sunday was confronted with the
                        reality that he lacks support from nearly a third.
                      </p>
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-behance"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className=" col-lg-4">
                    <div className="binduz-er-footer-gallery ml-50">
                      <div className="binduz-er-footer-title">
                        <h4 className="binduz-er-title">Photo Showcase</h4>
                      </div>
                      <div className="binduz-er-footer-gallery-widget d-flex">
                        <div className="binduz-er-item">
                          <a href="#">
                            <img
                              src={
                                require(`../../../../assets/images/footer-gallery-1.jpg`)
                                  .default
                              }
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="binduz-er-item">
                          <a href="#">
                            <img
                              src={
                                require(`../../../../assets/images/footer-gallery-2.jpg`)
                                  .default
                              }
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="binduz-er-item">
                          <a href="#">
                            <img
                              src={
                                require(`../../../../assets/images/footer-gallery-3.jpg`)
                                  .default
                              }
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                      <div className="binduz-er-footer-gallery-widget d-flex">
                        <div className="binduz-er-item">
                          <a href="#">
                            <img
                              src={
                                require(`../../../../assets/images/footer-gallery-4.jpg`)
                                  .default
                              }
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="binduz-er-item">
                          <a href="#">
                            <img
                              src={
                                require(`../../../../assets/images/footer-gallery-5.jpg`)
                                  .default
                              }
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="binduz-er-item">
                          <a href="#">
                            <img
                              src={
                                require(`../../../../assets/images/footer-gallery-6.jpg`)
                                  .default
                              }
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-lg-4">
                    <div className="binduz-er-footer-navigation">
                      <div className="binduz-er-footer-title">
                        <h3 className="binduz-er-title">Company Info</h3>
                      </div>
                      <div className="binduz-er-footer-list">
                        <ul>
                          <li>
                            <a href="/about">About us</a>
                          </li>
                          <li>
                            <a href="/contact">Terms of Service</a>
                          </li>
                          <li>
                            <a href="/contact">Contact us</a>
                          </li>
                          <li>
                            <a href="/blog-details-two">Local print ads</a>
                          </li>
                          <li>
                            <a href="/contact">FAQ</a>
                          </li>
                          <li>
                            <a href="/blog-details-two">Media kit</a>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <a href="/contact">Careers</a>
                          </li>
                          <li>
                            <a href="/contact">Privacy Policy</a>
                          </li>
                          <li>
                            <a href="/blog-details-two">Archives</a>
                          </li>
                          <li>
                            <a href="/blog-details-two">Coupons</a>
                          </li>
                          <li>
                            <a href="/blog-details-two">Manage Web Notifications</a>
                          </li>
                          <li>
                            <a href="/blog-details-two">Chicago Tribune Store</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="binduz-er-footer-copyright-area binduz-er-footer-copyright-area-2">
        <div className=" container">
          <div className="binduz-er-footer-copyright-box">
            <div className="row align-items-center">
              <div className=" col-lg-6">
                <div className="binduz-er-copyright-text">
                  <p>
                    Copyright By@<span>QuomodoTheme</span> - 2021
                  </p>
                </div>
              </div>
              <div className=" col-lg-6">
                <div className="binduz-er-copyright-menu float-lg-end float-none">
                  <ul>
                    <li>
                      <a href="#">Privacy & Policy</a>
                    </li>
                    <li>
                      <a href="#">Claim A Report</a>
                    </li>
                    <li>
                      <a href="#">Careers</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;

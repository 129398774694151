import React, { useRef, useState } from "react";
import DropDown from "../../../Helpers/DropDown";
import SliderCom from "../../../Helpers/SliderCom";

function TopHeader() {
  const category1 = ["English", "Saab", "VW", "Audi"];
  const [selectedCategory, setCategory] = useState(category1[0]);
  const changeCategory1 = (value) => {
    setCategory(value);
  };
  const sliderArrow = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
  };
  const handlePrev = () => sliderArrow.current.slickPrev();
  const handleNext = () => sliderArrow.current.slickNext();

  return (
    <div className="binduz-er-top-header-area-4 bg_cover d-none d-lg-block">
      <div className=" container">
        <div className="row align-items-center">
          <div className=" col-lg-6 col-md-7">
            <div className="binduz-er-top-header-wrapper">
              <div className="binduz-er-top-header-lang">
                <div className="binduz-er-select-item">
                  <DropDown
                    datas={category1}
                    selected={selectedCategory}
                    action={changeCategory1}
                    className="mexuvo-binduz-header-dropdown"
                  />
                </div>
              </div>
              <div className="binduz-er-top-header-weather">
                <ul>
                  <li>
                    <a href="#">
                      <i className="fal fa-cloud"></i> 22°F
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-cloud"></i> 22°F
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className=" col-lg-6 col-md-5">
            <div className="binduz-er-topbar-headline float-end">
              <span
                onClick={handlePrev}
                className="prev slick-arrow"
                style={{ display: "block" }}
              >
                <i className="fas fa-angle-left"></i>
              </span>
              <SliderCom selector={sliderArrow} settings={settings}>
                <p>
                  <span>
                    <i className="fas fa-bolt"></i> Trending News:
                  </span>
                  <a href="#">
                    Miranda halim was viral after finish his workout.
                  </a>
                </p>
                <p>
                  <span>
                    <i className="fas fa-bolt"></i> Trending News:
                  </span>
                  <a href="#">
                    Miranda halim was viral after finish his workout.
                  </a>
                </p>
                <p>
                  <span>
                    <i className="fas fa-bolt"></i> Trending News:
                  </span>
                  <a href="#">
                    Miranda halim was viral after finish his workout.
                  </a>
                  .
                </p>
              </SliderCom>
              <span
                onClick={handleNext}
                className="next slick-arrow"
                style={{ display: "block" }}
              >
                <i className="fas fa-angle-right"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopHeader;

import React from "react";
import { Link } from "react-router-dom";
import dateFormat from "../../../lib/dateFormat";

function PostRowCardStyleOne({
  datas = { image: "", title: "", date: "", description: "", category: "" },
}) {
  return (
    <div className="binduz-er-recent-news-item mb-20">
      <div className="binduz-er-thumb">
        <img
          src={require(`../../../assets/images/${datas.image}`).default}
          alt=""
        />
      </div>
      <div className="binduz-er-content">
        <div className="binduz-er-meta-item">
          <div className="binduz-er-meta-categories">
            <a href="#">{datas.category}</a>
          </div>
          <div className="binduz-er-meta-date">
            <span>
              <i className="fal fa-calendar-alt"></i> {dateFormat(datas.date)}
            </span>
          </div>
        </div>
        <h5 className="binduz-er-title">
          <Link to="/blog-details-two">{datas.title}</Link>
        </h5>
        <p className="line-clump-3">{datas.description}</p>
      </div>
    </div>
  );
}

export default PostRowCardStyleOne;

import React from "react";

function HomeThree() {
  return (
    <>
      <footer className="binduz-er-footer-3-area">
        <div className=" container">
          <div className="row">
            <div className=" col-lg-2 col-md-4 col-sm-6">
              <div className="binduz-er-footer-navigation-widget mt-30">
                <div className="binduz-er-footer-title">
                  <h3 className="binduz-er-title">Tech</h3>
                </div>
                <div className="binduz-er-navigarion-list">
                  <ul>
                    <li>
                      <a href="/about">Innovate</a>
                    </li>
                    <li>
                      <a href="/blog-details-one">Gadget</a>
                    </li>
                    <li>
                      <a href="/blog-details-one">Mission: Ahead</a>
                    </li>
                    <li>
                      <a href="/blog-details-one">Upstarts</a>
                    </li>
                    <li>
                      <a href="/blog-details-one">Work Transformed</a>
                    </li>
                    <li>
                      <a href="/blog-details-one">Innovative Cities</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className=" col-lg-2 col-md-4 col-sm-6">
              <div className="binduz-er-footer-navigation-widget mt-30">
                <div className="binduz-er-footer-title">
                  <h3 className="binduz-er-title">Style</h3>
                </div>
                <div className="binduz-er-navigarion-list">
                  <ul>
                    <li>
                      <a href="/blog-details-one">Arts</a>
                    </li>
                    <li>
                      <a href="/blog-details-one">Design</a>
                    </li>
                    <li>
                      <a href="/blog-details-one">Fashion</a>
                    </li>
                    <li>
                      <a href="/blog-details-one">Architecture</a>
                    </li>
                    <li>
                      <a href="/blog-details-one">Luxury</a>
                    </li>
                    <li>
                      <a href="/blog-details-one">Beauty</a>
                    </li>
                    <li>
                      <a href="/blog-details-one">Video</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className=" col-lg-2 col-md-4 col-sm-6">
              <div className="binduz-er-footer-navigation-widget mt-30">
                <div className="binduz-er-footer-title">
                  <h3 className="binduz-er-title">Travel</h3>
                </div>
                <div className="binduz-er-navigarion-list">
                  <ul>
                    <li>
                      <a href="#">Destinations</a>
                    </li>
                    <li>
                      <a href="#">Food & Drink</a>
                    </li>
                    <li>
                      <a href="#">News</a>
                    </li>
                    <li>
                      <a href="#">Stay</a>
                    </li>
                    <li>
                      <a href="#">Videos</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className=" col-lg-2 col-md-4 col-sm-6">
              <div className="binduz-er-footer-navigation-widget mt-30">
                <div className="binduz-er-footer-title">
                  <h3 className="binduz-er-title">Sports</h3>
                </div>
                <div className="binduz-er-navigarion-list">
                  <ul>
                    <li>
                      <a href="#">Pro Football</a>
                    </li>
                    <li>
                      <a href="#">College Football</a>
                    </li>
                    <li>
                      <a href="#">Basketball</a>
                    </li>
                    <li>
                      <a href="#">Baseball</a>
                    </li>
                    <li>
                      <a href="#">Soccer</a>
                    </li>
                    <li>
                      <a href="#">Olympics</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className=" col-lg-2 col-md-4 col-sm-6">
              <div className="binduz-er-footer-navigation-widget mt-30">
                <div className="binduz-er-footer-title">
                  <h3 className="binduz-er-title">Videos</h3>
                </div>
                <div className="binduz-er-navigarion-list">
                  <ul>
                    <li>
                      <a href="#">Live TV</a>
                    </li>
                    <li>
                      <a href="#">Digital Studios</a>
                    </li>
                    <li>
                      <a href="#">CNN Films</a>
                    </li>
                    <li>
                      <a href="#">HLN</a>
                    </li>
                    <li>
                      <a href="#">TV Schedule</a>
                    </li>
                    <li>
                      <a href="#">TV Shows A-Z</a>
                    </li>
                    <li>
                      <a href="#">OurVR</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className=" col-lg-2 col-md-4 col-sm-6">
              <div className="binduz-er-footer-navigation-widget mt-30">
                <div className="binduz-er-footer-title">
                  <h3 className="binduz-er-title">More</h3>
                </div>
                <div className="binduz-er-navigarion-list">
                  <ul>
                    <li>
                      <a href="#">Photos</a>
                    </li>
                    <li>
                      <a href="#">Longform</a>
                    </li>
                    <li>
                      <a href="#">Investigations</a>
                    </li>
                    <li>
                      <a href="#">Profiles</a>
                    </li>
                    <li>
                      <a href="#">Leadership</a>
                    </li>
                    <li>
                      <a href="#">Newsletters</a>
                    </li>
                    <li>
                      <a href="#">Work for Binduzz</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="binduz-er-footer-copyright-area">
        <div className=" container">
          <div className="row align-items-center">
            <div className=" col-lg-4">
              <div className="binduz-er-copyright-text">
                <p>
                  Copyright By@<span>QuomodoTheme</span> - 2021
                </p>
              </div>
            </div>
            <div className=" col-lg-8">
              <div className="binduz-er-copyright-menu float-lg-end float-none">
                <ul>
                  <li>
                    <a href="#">Terms of Use</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#">Accessibility & CC</a>
                  </li>
                  <li>
                    <a href="#">About Us</a>
                  </li>
                  <li>
                    <a href="#">Studio Tours</a>
                  </li>
                  <li>
                    <a href="#">Advertise with us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="binduz-er-footer-logo">
          <a href="/">
            <img
              src={require(`../../../../assets/images/logo-5.png`).default}
              alt=""
            />
          </a>
        </div>
      </div>
    </>
  );
}

export default HomeThree;

import React from "react";
import MainPostsContext from "../../contexts/HomeOne/MainPostsContext";
import TrendingNewsContext from "../../contexts/HomeOne/TrendingNewsContext";
import navigationContext from "../../contexts/navigationsContext";
import { navigations } from "../../Data/navigation.json";
import { posts } from "../../Data/posts.json";
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../Helpers/BackToTop";
import MainPostsSection from "../HomeOne/MainPostsSection";
import TodayTrending from "../HomeOne/TodayTrending";
import TrendingSection from "../HomeOne/TrendingSection";
import VideoPostsSection from "../HomeOne/VideoPostsSection";
import Drawer from "../Mobile/Drawer";
import Footer from "../Partials/Footers/HomeOne/Footer";
import HeaderOne from "../Partials/Headers/HeaderOne";
import FeatureSection from "./FeatureSection";

const HomeFive = () => {
  const trendingNews = posts;
  const videoPosts = posts;
  const todayTrending = posts;
  const mainPosts = posts;
  const navigationData = navigations;
  const treandingNewsCategoryTab = [
    {
      id: 1,
      name: "All",
    },
    {
      id: 2,
      name: "Travel",
    },
    {
      id: 6,
      name: "Entertainment",
    },
    {
      id: 3,
      name: "Fasion",
    },
    {
      id: 4,
      name: "Health",
    },
    {
      id: 5,
      name: "Nature",
    },
  ];
  const [drawer, setDrawer] = useToggle(false);
  return (
    <>
      <Drawer
        drawer={drawer}
        action={setDrawer.toggle}
        navigationData={navigationData}
      />
      <navigationContext.Provider value={{ navigationData }}>
        <HeaderOne drawerAction={setDrawer.toggle} />
      </navigationContext.Provider>
      <TrendingNewsContext.Provider value={{ trendingNews }}>
        <TrendingSection category={treandingNewsCategoryTab} />
      </TrendingNewsContext.Provider>
      <FeatureSection />
      <TodayTrending todayTrending={todayTrending} />
      <VideoPostsSection
        className="gray-bg"
        category={treandingNewsCategoryTab}
        videoPosts={videoPosts}
      />
      <MainPostsContext.Provider value={{ mainPosts }}>
        <MainPostsSection bg="#fff" />
      </MainPostsContext.Provider>
      <Footer />
      <BackToTop />
    </>
  );
};

export default HomeFive;

import React, { useRef } from "react";
import SliderCom from "../../components/Helpers/SliderCom";

function MostViews() {
  const mostViewsSlider = useRef(null);
  const settings = {
    ots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };
  const prevHandler = () => mostViewsSlider.current.slickPrev();
  const nextHandler = () => mostViewsSlider.current.slickNext();
  return (
    <>
      <div className="binduz-er-top-news-title">
        <h3 className="binduz-er-title">Most Viewed News</h3>
      </div>
      <div className="binduz-er-news-viewed-most-slide">
        <span
          onClick={prevHandler}
          className="prev slick-arrow"
          style={{ display: "block" }}
        >
          <i className="fal fa-long-arrow-left"></i>
        </span>
        <SliderCom selector={mostViewsSlider} settings={settings}>
          <div className="binduz-er-news-viewed-most">
            <div className="binduz-er-thumb">
              <img
                src={require(`../../assets/images/most-view-1.png`).default}
                alt=""
              />
            </div>
            <div className="binduz-er-content">
              <div className="binduz-er-meta-item">
                <div className="binduz-er-meta-categories">
                  <a href="#">Technology</a>
                </div>
                <div className="binduz-er-meta-date">
                  <span>
                    <i className="fal fa-calendar-alt"></i> 24th February 2020
                  </span>
                </div>
              </div>
              <h4 className="binduz-er-title">
                <a href="#">
                  How location helps provide more relevant search results
                </a>
              </h4>
              <div className="binduz-er-meta-author">
                <img
                  src={require(`../../assets/images/user-2.jpg`).default}
                  alt=""
                />
                <span>
                  By <span>Miranda H. Halim</span>
                </span>
              </div>
            </div>
          </div>
          <div className="binduz-er-news-viewed-most">
            <div className="binduz-er-thumb">
              <img
                src={require(`../../assets/images/most-view-2.png`).default}
                alt=""
              />
            </div>
            <div className="binduz-er-content">
              <div className="binduz-er-meta-item">
                <div className="binduz-er-meta-categories">
                  <a href="#">Technology</a>
                </div>
                <div className="binduz-er-meta-date">
                  <span>
                    <i className="fal fa-calendar-alt"></i> 24th February 2020
                  </span>
                </div>
              </div>
              <h4 className="binduz-er-title">
                <a href="#">
                  Producer Peter CottonTale captures 2020 in a song
                </a>
              </h4>
              <div className="binduz-er-meta-author">
                <img
                  src={require(`../../assets/images/user-2.jpg`).default}
                  alt=""
                />
                <span>
                  By <span>Miranda H. Halim</span>
                </span>
              </div>
            </div>
          </div>
        </SliderCom>
        <span
          onClick={nextHandler}
          className="next slick-arrow"
          style={{ display: "block" }}
        >
          <i className="fal fa-long-arrow-right"></i>
        </span>
      </div>
    </>
  );
}

export default MostViews;

import React, { useRef } from "react";
import SliderCom from "../Helpers/SliderCom";

function TopNewsSection() {
  const sliderArrows = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  const prevHandler = () => sliderArrows.current.slickPrev();
  const nextHandler = () => sliderArrows.current.slickNext();

  return (
    <section className="binduz-er-top-news-2-area pt-40">
      <div className=" container">
        <div className="row">
          <div className=" col-lg-4">
            <div className="binduz-er-video-post binduz-er-top-news-2-slider">
              <span
                onClick={prevHandler}
                className="prev slick-arrow"
                style={{ display: "block" }}
              >
                <i className="fal fa-angle-left"></i>
              </span>
              <SliderCom selector={sliderArrows} settings={settings}>
                <div className="binduz-er-latest-news-item">
                  <div className="binduz-er-thumb">
                    <img
                      src={
                        require(`../../assets/images/top-news-item-1.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="binduz-er-content">
                    <div className="binduz-er-meta-item">
                      <div className="binduz-er-meta-categories">
                        <a href="#">Technology</a>
                      </div>
                      <div className="binduz-er-meta-date">
                        <span>
                          <i className="fal fa-calendar-alt"></i>24th February
                          2020
                        </span>
                      </div>
                    </div>
                    <h5 className="binduz-er-title">
                      <a href="#">When artists and machine intelligence come</a>
                    </h5>
                  </div>
                </div>
                <div className="binduz-er-latest-news-item">
                  <div className="binduz-er-thumb">
                    <img
                      src={
                        require(`../../assets/images/top-news-item-4.jpg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="binduz-er-content">
                    <div className="binduz-er-meta-item">
                      <div className="binduz-er-meta-categories">
                        <a href="#">Technology</a>
                      </div>
                      <div className="binduz-er-meta-date">
                        <span>
                          <i className="fal fa-calendar-alt"></i>24th February
                          2020
                        </span>
                      </div>
                    </div>
                    <h5 className="binduz-er-title">
                      <a href="#">
                        Google Translate: One billion installs, one
                      </a>
                    </h5>
                  </div>
                </div>
              </SliderCom>
              <span
                onClick={nextHandler}
                className="next slick-arrow"
                style={{ display: "block" }}
              >
                <i className="fal fa-angle-right"></i>
              </span>
            </div>
          </div>
          <div className=" col-lg-4">
            <div className="binduz-er-top-news-2-item">
              <div className="binduz-er-trending-news-item mb-30">
                <div className="binduz-er-trending-news-overlay">
                  <div className="binduz-er-trending-news-meta">
                    <div className="binduz-er-meta-categories">
                      <a href="#">Technology</a>
                    </div>
                    <div className="binduz-er-meta-date">
                      <span>
                        <i className="fal fa-calendar-alt"></i> 24th February
                        2020
                      </span>
                    </div>
                    <div className="binduz-er-trending-news-title">
                      <h3 className="binduz-er-title">
                        <a href="#">
                          Loud and clear: AI is improving Assistant
                          conversations
                        </a>
                      </h3>
                    </div>
                  </div>
                  <div className="binduz-er-news-share">
                    <a href="#">
                      <i className="fal fa-share"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-lg-4">
            <div className="binduz-er-top-news-2-item binduz-er-top-news-2-1-item">
              <div className="binduz-er-trending-news-item mb-30">
                <div className="binduz-er-trending-news-overlay">
                  <div className="binduz-er-trending-news-meta">
                    <div className="binduz-er-meta-categories">
                      <a href="#">Technology</a>
                    </div>
                    <div className="binduz-er-meta-date">
                      <span>
                        <i className="fal fa-calendar-alt"></i> 24th February
                        2020
                      </span>
                    </div>
                    <div className="binduz-er-trending-news-title">
                      <h3 className="binduz-er-title">
                        <a href="#">
                          Tips and shortcuts for a more productive spring
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="binduz-er-trending-news-item item-2 mb-30">
                <div className="binduz-er-trending-news-overlay">
                  <div className="binduz-er-trending-news-meta">
                    <div className="binduz-er-meta-categories">
                      <a href="#">Technology</a>
                    </div>
                    <div className="binduz-er-meta-date">
                      <span>
                        <i className="fal fa-calendar-alt"></i> 24th February
                        2020
                      </span>
                    </div>
                    <div className="binduz-er-trending-news-title">
                      <h3 className="binduz-er-title">
                        <a href="#">
                          Helpful tools for when you're ready to travel
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TopNewsSection;

import React from "react";
import Navigation from "../../../Helpers/Navigation";

const MainHeader = ({ drawerAction, searchAction }) => (
  <>
    <header className="binduz-er-header-area">
      <div className="binduz-er-header-nav">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="navigation">
                <nav className="navbar navbar-expand-lg">
                  <div className="navbar-brand logo">
                    <a href="/">
                      <img
                        src={
                          require(`../../../../assets/images/logo.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div
                    className="collapse navbar-collapse sub-menu-bar"
                    id="navbarSupportedContent"
                  >
                    <Navigation className="m-auto" />
                  </div>
                  <div className="binduz-er-navbar-btn d-flex">
                    <div className="binduz-er-widget d-flex">
                      <a
                        onClick={(e) => searchAction(e)}
                        className="binduz-er-news-search-open"
                        href="#"
                      >
                        <i className="far fa-search"></i>
                      </a>
                      <a href="#">
                        <i className="far fa-user"></i>
                      </a>
                    </div>
                    <span
                      onClick={drawerAction}
                      className="binduz-er-toggle-btn binduz-er-news-canvas_open d-block d-lg-none"
                    >
                      <i className="fal fa-bars"></i>
                    </span>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </>
);

export default MainHeader;

import React, { useRef } from "react";
import SliderCom from "../../components/Helpers/SliderCom";

function SocialNewsSlider() {
  const socialNewsSlider = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  const prevHandler = () => socialNewsSlider.current.slickPrev();
  const nextHandler = () => socialNewsSlider.current.slickNext();
  return (
    <div className="binduz-er-social-news-box">
      <div className="binduz-er-social-news-title">
        <h3 className="binduz-er-title">Social News</h3>
      </div>
      <div className="row binduz-er-social-news-slide">
        <span
          onClick={prevHandler}
          className="prev slick-arrow"
          style={{ display: "block" }}
        >
          <i className="fal fa-angle-left"></i>
        </span>
        <SliderCom selector={socialNewsSlider} settings={settings}>
          <div className="col-lg-4 social-news-slider-gap">
            <div className="binduz-er-social-news-item">
              <img
                src={require(`../../assets/images/social-news-1.jpg`).default}
                alt=""
              />
              <div className="binduz-er-social-btn">
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </div>
            </div>
          </div>
          <div className=" col-lg-4 social-news-slider-gap">
            <div className="binduz-er-social-news-item">
              <img
                src={require(`../../assets/images/social-news-2.jpg`).default}
                alt=""
              />
              <div className="binduz-er-social-btn binduz-er-twitter">
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
              </div>
            </div>
          </div>
          <div className=" col-lg-4 social-news-slider-gap">
            <div className="binduz-er-social-news-item">
              <img
                src={require(`../../assets/images/social-news-3.jpg`).default}
                alt=""
              />
              <div className="binduz-er-social-btn binduz-er-youtube">
                <a href="#">
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
          <div className=" col-lg-4 social-news-slider-gap">
            <div className="binduz-er-social-news-item">
              <img
                src={require(`../../assets/images/social-news-2.jpg`).default}
                alt=""
              />
              <div className="binduz-er-social-btn binduz-er-youtube">
                <a href="#">
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
        </SliderCom>
        <span
          onClick={nextHandler}
          className="next slick-arrow"
          style={{ display: "block" }}
        >
          <i className="fal fa-angle-right"></i>
        </span>
      </div>
    </div>
  );
}

export default SocialNewsSlider;

import React, { useRef } from "react";
import PostCardStyleOne from "../../containers/HomeOne/PostCards/PostCardStyleOne";
import SocialFeed from "../../containers/HomeOne/SocialFeed";
import SliderCom from "../Helpers/SliderCom";

function FeatureSection({ featurePosts }) {
  const featureSlider = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const prevHandler = () => featureSlider.current.slickPrev();
  const nextHandler = () => featureSlider.current.slickNext();
  return (
    <>
      <section className="binduz-er-featured-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="binduz-er-featured-slider mt-30">
                <div className="binduz-er-featured-title">
                  <h3 className="binduz-er-title">Featured</h3>
                </div>
                <div className="binduz-er-featured-slider-item">
                  <span
                    onClick={() => prevHandler()}
                    className="prev slick-arrow"
                    style={{ display: "block" }}
                  >
                    <i className="far fa-angle-left"></i>
                  </span>
                  <SliderCom selector={featureSlider} settings={settings}>
                    {featurePosts &&
                      featurePosts.length > 0 &&
                      featurePosts.slice(6, 8).map((item, index) => (
                          <div  key={item.id + Math.random()} className="binduz-er-video-post-item">
                            <PostCardStyleOne
                                video={false}
                                datas={{
                                  image: item.post_images.feature_news[index],
                                  category: item.post_cat[0],
                                  date: item.post_date,
                                  title: item.post_title,
                                }}
                            />
                          </div>

                      ))}
                  </SliderCom>
                  <span
                    onClick={() => nextHandler()}
                    className="next slick-arrow"
                    style={{ display: "block" }}
                  >
                    <i className="far fa-angle-right"></i>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6">
              <div className="binduz-er-trending-news-item mb-30">
                <img
                  src={
                    require(`../../assets/images/featured-trending-thumb-1.jpg`)
                      .default
                  }
                  alt=""
                />
                <div className="binduz-er-trending-news-overlay">
                  <div className="binduz-er-trending-news-meta">
                    <div className="binduz-er-meta-categories">
                      <a href="#">Technology</a>
                    </div>
                    <div className="binduz-er-meta-date">
                      <span>
                        <i className="fal fa-calendar-alt"></i> 24th February
                        2020
                      </span>
                    </div>
                    <div className="binduz-er-trending-news-title">
                      <h3 className="binduz-er-title">
                        <a href="#">
                          Trash to treasure: How Google thinks about
                          deconstruction
                        </a>
                      </h3>
                    </div>
                  </div>
                  <div className="binduz-er-news-share">
                    <a href="#">
                      <i className="fal fa-share"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="binduz-er-trending-news-item mb-30">
                <img
                  src={
                    require(`../../assets/images/featured-trending-thumb-2.jpg`)
                      .default
                  }
                  alt=""
                />
                <div className="binduz-er-trending-news-overlay">
                  <div className="binduz-er-trending-news-meta">
                    <div className="binduz-er-meta-categories">
                      <a href="#">Technology</a>
                    </div>
                    <div className="binduz-er-meta-date">
                      <span>
                        <i className="fal fa-calendar-alt"></i> 24th February
                        2020
                      </span>
                    </div>
                    <div className="binduz-er-trending-news-title">
                      <h3 className="binduz-er-title">
                        <a href="#">
                          Spring Fashion Show at the University of Michigan Has
                          Started.
                        </a>
                      </h3>
                    </div>
                  </div>
                  <div className="binduz-er-news-share">
                    <a href="#">
                      <i className="fal fa-share"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <SocialFeed />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FeatureSection;

import React from "react";

function NewsLetterPartStart() {
  return (
    <section className="binduz-er-newsletter-area pb-30">
      <div className=" container">
        <div className="row">
          <div className=" col-lg-4">
            <div className="binduz-er-video-post binduz-er-newsletter-post">
              <div className="binduz-er-latest-news-item">
                <div className="binduz-er-content">
                  <div className="binduz-er-meta-item">
                    <div className="binduz-er-meta-categories">
                      <a href="#">Technology</a>
                    </div>
                    <div className="binduz-er-meta-date">
                      <span>
                        <i className="fal fa-calendar-alt"></i>24th February
                        2020
                      </span>
                    </div>
                  </div>
                  <h5 className="binduz-er-title">
                    <a href="#">
                      Fashion Show at the University of Michigan Has Started.
                    </a>
                  </h5>
                </div>
                <div className="binduz-er-thumb">
                  <img
                    src={
                      require(`../../assets/images/newsletter-news-1.jpg`)
                        .default
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className=" col-lg-4">
            <div className="binduz-er-newsletter-box text-center">
              <img
                src={require(`../../assets/images/icon/icon-3.png`).default}
                alt=""
              />
              <h3 className="binduz-er-title">Get Every Newsletter</h3>
              <p>Don’t worry we are not gonna make spamming</p>
              <div className="binduz-er-input-box">
                <input type="text" placeholder="Enter your email" />
                <button type="button">
                  <i className="fal fa-long-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
          <div className=" col-lg-4">
            <div className="binduz-er-video-post binduz-er-newsletter-post">
              <div className="binduz-er-latest-news-item">
                <div className="binduz-er-thumb">
                  <img
                    src={
                      require(`../../assets/images/newsletter-news-2.jpg`)
                        .default
                    }
                    alt=""
                  />
                </div>
                <div className="binduz-er-content">
                  <div className="binduz-er-meta-item">
                    <div className="binduz-er-meta-categories">
                      <a href="#">Technology</a>
                    </div>
                    <div className="binduz-er-meta-date">
                      <span>
                        <i className="fal fa-calendar-alt"></i>24th February
                        2020
                      </span>
                    </div>
                  </div>
                  <h5 className="binduz-er-title">
                    <a href="#">
                      A quick way to learn more about your search results
                    </a>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewsLetterPartStart;

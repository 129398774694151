import React from "react";

function SidebarAbout() {
  return (
    <div className="binduz-er-sidebar-about">
      <div className="binduz-er-sidebar-title">
        <h4 className="binduz-er-title">About Me</h4>
      </div>
      <div className="binduz-er-sidebar-about-item">
        <div className="binduz-er-sidebar-about-user d-flex">
          <div className="binduz-er-thumb">
            <img src={require(`../../assets/images/user.jpg`).default} alt="" />
          </div>
          <div className="binduz-er-content">
            <h4 className="binduz-er-title">Miranda Halim</h4>
            <span>Author</span>
            <ul>
              <li>
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-behance"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-linkedin"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="binduz-er-text">
          <p>
            The functional aspect comes first in the work process because it’s
            the core of the object: What is its purpose? something else?
          </p>
          <a className="binduz-er-main-btn" href="#">
            Connect With Me
          </a>
        </div>
      </div>
    </div>
  );
}

export default SidebarAbout;

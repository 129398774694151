import React from "react";
import MainPosts from "../../containers/HomeOne/MainPosts";
import SidebarAbout from "../../containers/HomeOne/SidebarAbout";
import SidebarLatestPosts from "../../containers/HomeOne/SidebarLatestPosts";

function MainPostsSection({ bg }) {
  return (
    <>
      <section
        className="binduz-er-main-posts-area"
        style={{ backgroundColor: bg || "" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="binduz-er-video-post-topbar">
                <div className="binduz-er-video-post-title">
                  <h3 className="binduz-er-title">Main Posts</h3>
                </div>
              </div>
              <MainPosts />
              <div className="binduz-er-add pt-10">
                <img
                  src={require(`../../assets/images/space-thumb.jpg`).default}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-3">
              <SidebarAbout />
              <SidebarLatestPosts />
              <div className="binduz-er-sidebar-add-box mt-40">
                <div className="binduz-er-logo">
                  <a href="#">
                    <img
                      src={require(`../../assets/images/logo-2.png`).default}
                      alt=""
                    />
                  </a>
                </div>
                <p>
                  Built with amazing platform & framwork. You can use anyway in
                  blog, news & magazine.
                </p>
                <a className="binduz-er-main-btn" href="#">
                  Purchase Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="binduz-er-social-share-area">
        <div className="binduz-er-social-share-box d-flex">
          <div className="binduz-er-social-share-item">
            <a href="#">
              <i className="fab fa-instagram"></i>
              <span>
                Instagram <span>Feeds</span>
              </span>
            </a>
          </div>
          <div className="binduz-er-social-share-item binduz-facebook">
            <a href="#">
              <i className="fab fa-facebook-f"></i>
              <span>
                Facebook <span>Share</span>
              </span>
            </a>
          </div>
          <div className="binduz-er-social-share-item binduz-twitter">
            <a href="#">
              <i className="fab fa-twitter"></i>
              <span>
                Twitter <span>Feeds</span>
              </span>
            </a>
          </div>
          <div className="binduz-er-social-share-item binduz-pinterest">
            <a href="#">
              <i className="fab fa-pinterest-p"></i>
              <span>
                Instagram <span>Feeds</span>
              </span>
            </a>
          </div>
          <div className="binduz-er-social-share-item binduz-dribbble">
            <a href="#">
              <i className="fab fa-dribbble"></i>
              <span>
                Instagram <span>Feeds</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainPostsSection;

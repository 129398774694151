import React from "react";
import useToggle from "../../../../Hooks/useToggle";
import Search from "../../../Search";
import MainHeader from "./MainHeader";
import TopHeader from "./TopHeader";

function HeaderTwo({ drawerAction, drawerPosition }) {
  const [show, setValue] = useToggle(false);
  return (
    <>
      <Search
        className={`${show ? "open" : ""}`}
        searchAction={setValue.toggle}
      />
      <TopHeader
        searchAction={setValue.toggle}
        drawerAction={drawerAction}
        drawerPosition={drawerPosition}
      />
      <MainHeader />
    </>
  );
}

export default HeaderTwo;

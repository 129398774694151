import React from "react";
import { Link } from "react-router-dom";
import VideoPopUp from "../../../components/Helpers/VideoPopup";
import useToggle from "../../../Hooks/useToggle";
import dateFormat from "../../../lib/dateFormat";

function CardStyleHomeTwo({
  datas = { image: "", category: "", title: "", date: "" },
}) {
  const [show, setValue] = useToggle(false);
  return (
    <>
      {show && (
        <VideoPopUp
          handler={setValue.toggle}
          videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
        />
      )}
      <div className="binduz-er-latest-news-item">
        <div className="binduz-er-thumb">
          <img
            src={require(`../../../assets/images/${datas.image}`).default}
            alt=""
          />
          <div className="binduz-er-play">
            <a
              onClick={(e) => setValue.toggle(e)}
              className="binduz-er-video-popup"
              href="#"
            >
              <i className="fas fa-play"></i>
            </a>
          </div>
        </div>
        <div className="binduz-er-content">
          <div className="binduz-er-meta-item">
            <div className="binduz-er-meta-categories">
              <a href="#">{datas.category}</a>
            </div>
            <div className="binduz-er-meta-date">
              <span>
                <i className="fal fa-calendar-alt"></i>
                {dateFormat(datas.date)}
              </span>
            </div>
          </div>
          <h5 className="binduz-er-title line-clump-2">
            <Link to="/blog-details-two">{datas.title}</Link>
          </h5>
        </div>
      </div>
    </>
  );
}

export default CardStyleHomeTwo;

import React, { useRef } from "react";
import SliderCom from "../../components/Helpers/SliderCom";

function SingleSlider({ title }) {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const sliderRef = useRef(null);
  const arrowHandler = (value) => {
    if (value === "prev") {
      sliderRef.current.slickPrev();
    } else if (value === "next") {
      sliderRef.current.slickNext();
    }
  };
  return (
    <div className="binduz-er-featured-slider mt-30">
      <div className="binduz-er-featured-title">
        <h3 className="binduz-er-title">{title}</h3>
      </div>
      <div className="binduz-er-featured-slider-item">
        <span
          onClick={() => arrowHandler("prev")}
          className="prev slick-arrow"
          style={{ display: "block" }}
        >
          <i className="far fa-angle-left"></i>
        </span>
        <SliderCom selector={sliderRef} settings={settings}>
          <div className="binduz-er-trending-news-list-box">
            <div className="binduz-er-thumb">
              <img
                src={
                  require(`../../assets/images/feature-news-thuimb.jpg`).default
                }
                alt=""
              />
            </div>
            <div className="binduz-er-content">
              <div className="binduz-er-meta-item">
                <div className="binduz-er-meta-categories">
                  <a href="#">Technology</a>
                </div>
                <div className="binduz-er-meta-date">
                  <span>
                    <i className="fal fa-calendar-alt"></i> 14th February 2020
                  </span>
                </div>
              </div>
              <div className="binduz-er-trending-news-list-title">
                <h4 className="binduz-er-title">
                  <a href="#">
                    JYSK boosts frontline teams with Android Enterprise
                  </a>
                </h4>
              </div>
            </div>
          </div>
          <div className="binduz-er-trending-news-list-box">
            <div className="binduz-er-thumb">
              <img
                src={
                  require(`../../assets/images/feature-news-thuimb-2.jpg`)
                    .default
                }
                alt=""
              />
            </div>
            <div className="binduz-er-content">
              <div className="binduz-er-meta-item">
                <div className="binduz-er-meta-categories">
                  <a href="#">Technology</a>
                </div>
                <div className="binduz-er-meta-date">
                  <span>
                    <i className="fal fa-calendar-alt"></i> 14th February 2020
                  </span>
                </div>
              </div>
              <div className="binduz-er-trending-news-list-title">
                <h4 className="binduz-er-title">
                  <a href="#">
                    Escucha las historias de los educadores en esta
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </SliderCom>
        <span
          onClick={() => arrowHandler("next")}
          className="next slick-arrow"
          style={{ display: "block" }}
        >
          <i className="far fa-angle-right"></i>
        </span>
      </div>
    </div>
  );
}

export default SingleSlider;

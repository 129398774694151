import { Route, Routes } from "react-router-dom";
import About from "./components/About/index";
import Archive from "./components/Archive/index";
import AuthorSection from "./components/AuthorSection/index";
import BlogOne from "./components/Blogs/BlogOne/index";
import BlogTwo from "./components/Blogs/BlogTwo/index";
import Contact from "./components/Contact/index";
import HomeEight from "./components/HomeEight/index";
import HomeEleven from "./components/HomeEleven/index";
import HomeFive from "./components/HomeFive/index";
import HomeFour from "./components/HomeFour/index";
import HomeNine from "./components/HomeNine";
import HomeOne from "./components/HomeOne/index";
import HomeSeven from "./components/HomeSeven/index";
import HomeSix from "./components/HomeSix/index";
import HomeTen from "./components/HomeTen/index";
import HomeThree from "./components/HomeThree/index";
import HomeTwelve from "./components/HomeTwelve/index";
import HomeTwo from "./components/HomeTwo/index";

export default function Routers() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<HomeOne />} />
        <Route exact path="/home-two" element={<HomeTwo />} />
        <Route exact path="/home-three" element={<HomeThree />} />
        <Route exact path="/home-four" element={<HomeFour />} />
        <Route exact path="/home-five" element={<HomeFive />} />
        <Route exact path="/home-six" element={<HomeSix />} />
        <Route exact path="/home-seven" element={<HomeSeven />} />
        <Route exact path="/home-eight" element={<HomeEight />} />
        <Route exact path="/home-nine" element={<HomeNine />} />
        <Route exact path="/home-ten" element={<HomeTen />} />
        <Route exact path="/home-eleven" element={<HomeEleven />} />
        <Route exact path="/home-twelve" element={<HomeTwelve />} />
        <Route exact path="/blog-details-one" element={<BlogOne />} />
        <Route exact path="/blog-details-two" element={<BlogTwo />} />
        <Route exact path="/archived" element={<Archive />} />
        <Route exact path="/author" element={<AuthorSection />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<Contact />} />
      </Routes>
    </>
  );
}

import React from "react";
import MostViews from "../../containers/HomeTwo/MostViews";
import SocialList from "../../containers/HomeTwo/SocialList";
import TopNews from "../../containers/HomeTwo/TopNews";
import TopNewsFaq from "../../containers/HomeTwo/TopNewsFaq";

function TopNewsSection({ className }) {
  return (
    <section className={`binduz-er-top-news-area ${className || ""}`}>
      <div className=" container">
        <div className="row">
          <div className="col-lg-4">
            <TopNews />
          </div>
          <div className=" col-lg-4">
            <MostViews />
          </div>
          <div className=" col-lg-4">
            <TopNewsFaq />
            <SocialList />
          </div>
        </div>
      </div>
    </section>
  );
}

export default TopNewsSection;

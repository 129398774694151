import React from "react";

function FavoriteCategories({ className }) {
  return (
    <section
      className={`binduz-er-favorites-categories-area ${className || ""}`}
    >
      <div className=" container">
        <div className="row">
          <div className=" col-lg-12">
            <div className="binduz-er-favorites-categories-box">
              <div className="binduz-er-top-news-title">
                <h3 className="binduz-er-title">Favorites Categories</h3>
              </div>
              <div className="binduz-er-favorites-categories-list">
                <div className="binduz-er-item">
                  <a href="#">
                    <img
                      src={
                        require(`../../assets/images/favorites-categories-1.png`)
                          .default
                      }
                      alt=""
                    />
                    <span>Sports</span>
                  </a>
                </div>
                <div className="binduz-er-item">
                  <a href="#">
                    <img
                      src={
                        require(`../../assets/images/favorites-categories-2.png`)
                          .default
                      }
                      alt=""
                    />
                    <span>Covid-19</span>
                  </a>
                </div>
                <div className="binduz-er-item">
                  <a href="#">
                    <img
                      src={
                        require(`../../assets/images/favorites-categories-3.png`)
                          .default
                      }
                      alt=""
                    />
                    <span>Journal</span>
                  </a>
                </div>
                <div className="binduz-er-item">
                  <a href="#">
                    <img
                      src={
                        require(`../../assets/images/favorites-categories-4.png`)
                          .default
                      }
                      alt=""
                    />
                    <span>Beating</span>
                  </a>
                </div>
                <div className="binduz-er-item">
                  <a href="#">
                    <img
                      src={
                        require(`../../assets/images/favorites-categories-5.png`)
                          .default
                      }
                      alt=""
                    />
                    <span>Movies</span>
                  </a>
                </div>
                <div className="binduz-er-item">
                  <a href="#">
                    <img
                      src={
                        require(`../../assets/images/favorites-categories-6.png`)
                          .default
                      }
                      alt=""
                    />
                    <span>Magazine</span>
                  </a>
                </div>
                <div className="binduz-er-item">
                  <a href="#">
                    <img
                      src={
                        require(`../../assets/images/favorites-categories-7.png`)
                          .default
                      }
                      alt=""
                    />
                    <span>Film</span>
                  </a>
                </div>
                <div className="binduz-er-item">
                  <a href="#">
                    <img
                      src={
                        require(`../../assets/images/favorites-categories-8.png`)
                          .default
                      }
                      alt=""
                    />
                    <span>Games</span>
                  </a>
                </div>
                <div className="binduz-er-item">
                  <a href="#">
                    <img
                      src={
                        require(`../../assets/images/favorites-categories-9.png`)
                          .default
                      }
                      alt=""
                    />
                    <span>Nature</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FavoriteCategories;

import React, { useState } from "react";
import PostRowCardStyleOne from "../../containers/HomeOne/PostCards/PostRowCardStyleOne";
import PostCardStyleOneHomeThree from "../../containers/HomeThree/Cards/PostCardStyleOneHomeThree";
import NewsGrid from "../../containers/HomeThree/NewsGrid";
import SocialList from "../../containers/HomeTwo/SocialList";
import Ads from "../HomeTwo/Ads";

function PopularNewsSection({ popularNewsData }) {
  const tabCategory = [
    {
      id: 1,
      name: "Popular",
    },
    {
      id: 2,
      name: "trending",
    },
    {
      id: 3,
      name: "Hot news",
    },
    {
      id: 4,
      name: "Recent",
    },
  ];
  const [tab, setTab] = useState(tabCategory[0].id);
  const tabHandler = (e, value) => {
    e.preventDefault();
    setTab(value);
  };
  return (
    <section className="binduz-er-popular-news-area pt-60 pb-25">
      <div className=" container">
        <div className="row">
          <div className=" col-lg-9">
            <div>
              <div className="binduz-er-popular-news-title">
                <h4 className="binduz-er-title">Popular News</h4>
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  {tabCategory &&
                    tabCategory.length > 0 &&
                    tabCategory.map((tabItem) => (
                      <li
                        key={tabItem.id}
                        className="nav-item"
                        role="presentation"
                      >
                        <a
                          onClick={(e) => tabHandler(e, tabItem.id)}
                          className={`nav-link ${
                            tab === tabItem.id ? "active" : ""
                          }`}
                          id="pills-1-tab"
                          data-bs-toggle="pill"
                          href="#pills-1"
                          role="tab"
                          aria-controls="pills-1"
                          aria-selected="true"
                        >
                          {tabItem.name}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="tab-content" id="pills-tabContent">
                {tabCategory &&
                  tabCategory.length > 0 &&
                  tabCategory.map((tabItem) => (
                    <div
                      key={tabItem.id}
                      className={`tab-pane fade ${
                        tabItem.id === tab ? "show active" : ""
                      }`}
                      id="pills-1"
                      role="tabpanel"
                      aria-labelledby="pills-1-tab"
                    >
                      <div className="binduz-er-popular-news-items">
                        <div className="row g-0">
                          {popularNewsData &&
                            popularNewsData.length > 0 &&
                            popularNewsData
                              .slice(0, 6)
                              .map((popularNewsItem, index) => (
                                <div className="col-lg-6 col-md-6">
                                  <PostCardStyleOneHomeThree
                                    key={popularNewsItem.id}
                                    datas={{
                                      image:
                                        popularNewsItem.post_images
                                          .popular_news[index],
                                      date: popularNewsItem.post_date,
                                      title: popularNewsItem.post_title,
                                      category: popularNewsItem.post_cat[0],
                                    }}
                                  />
                                </div>
                              ))}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <Ads className="pt-25 pb-0" />
              <div className="binduz-er-editors-pack-item mt-60">
                <NewsGrid
                  gridClasses="col-lg-4 col-md-6"
                  editorPackSectionDatas={popularNewsData}
                  dataLength="3"
                />
              </div>
            </div>
          </div>
          <div className=" col-lg-3">
            <div className="binduz-er-populer-news-sidebar">
              <div className="binduz-er-populer-news-social">
                <div className="binduz-er-popular-news-title">
                  <h3 className="binduz-er-title">Social Connects</h3>
                </div>
                <SocialList />
              </div>
              <div className="binduz-er-populer-news-sidebar-post pt-30">
                <div className="binduz-er-popular-news-title">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab-2"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        href="#pills-home"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Most Popular
                      </a>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        href="#pills-profile"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Most Recent
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div className="tab-content" id="pills-tabContent-2">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="binduz-er-sidebar-latest-post-box">
                      {popularNewsData &&
                        popularNewsData.length > 0 &&
                        popularNewsData
                          .slice(0, 4)
                          .map((popularNewsItem, index) => (
                            <PostRowCardStyleOne
                              key={popularNewsItem.id}
                              datas={{
                                image:
                                  popularNewsItem.post_images.latest_posts[
                                    index
                                  ],
                                date: popularNewsItem.post_date,
                                title: popularNewsItem.post_title,
                              }}
                            />
                          ))}
                    </div>
                  </div>
                  {/* <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="binduz-er-sidebar-latest-post-box">
                      <div className="binduz-er-sidebar-latest-post-item">
                        <div className="binduz-er-thumb">
                          <img
                            src={
                              require(`../../assets/images/latest-post-1.jpg`)
                                .default
                            }
                            alt="latest"
                          />
                        </div>
                        <div className="binduz-er-content">
                          <span>
                            <i className="fal fa-calendar-alt"></i> 24th
                            February 2020
                          </span>
                          <h4 className="binduz-er-title">
                            <a href="#">
                              Sparks of inspiration to the new trend 2021
                            </a>
                          </h4>
                        </div>
                      </div>
                      <div className="binduz-er-sidebar-latest-post-item">
                        <div className="binduz-er-thumb">
                          <img
                            src={
                              require(`../../assets/images/latest-post-2.jpg`)
                                .default
                            }
                            alt="latest"
                          />
                        </div>
                        <div className="binduz-er-content">
                          <span>
                            <i className="fal fa-calendar-alt"></i> 24th
                            February 2020
                          </span>
                          <h4 className="binduz-er-title">
                            <a href="#">
                              Sparks of inspiration to the new trend 2021
                            </a>
                          </h4>
                        </div>
                      </div>
                      <div className="binduz-er-sidebar-latest-post-item">
                        <div className="binduz-er-thumb">
                          <img
                            src={
                              require(`../../assets/images/latest-post-3.jpg`)
                                .default
                            }
                            alt="latest"
                          />
                        </div>
                        <div className="binduz-er-content">
                          <span>
                            <i className="fal fa-calendar-alt"></i> 24th
                            February 2020
                          </span>
                          <h4 className="binduz-er-title">
                            <a href="#">
                              Sparks of inspiration to the new trend 2021
                            </a>
                          </h4>
                        </div>
                      </div>
                      <div className="binduz-er-sidebar-latest-post-item">
                        <div className="binduz-er-thumb">
                          <img
                            src={
                              require(`../../assets/images/latest-post-4.jpg`)
                                .default
                            }
                            alt="latest"
                          />
                        </div>
                        <div className="binduz-er-content">
                          <span>
                            <i className="fal fa-calendar-alt"></i> 24th
                            February 2020
                          </span>
                          <h4 className="binduz-er-title">
                            <a href="#">
                              Sparks of inspiration to the new trend 2021
                            </a>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="binduz-er-populer-news-sidebar-faq mt-40">
                <div className="binduz-er-top-news-faq">
                  <h4 className="binduz-er-title">
                    House is democrats wrangle over madigan’s future.
                  </h4>
                  <div className="binduz-er-meta-author">
                    <span>
                      By <span>Miranda H. Halim</span>
                    </span>
                  </div>
                  <div className="binduz-er-answare">
                    <p>Get Ask Amy delivered to your inbox every morning</p>
                  </div>
                </div>
              </div>
              <div className="binduz-er-populer-news-sidebar-newsletter mt-40">
                <div className="binduz-er-newsletter-box text-center">
                  <img
                    src={require(`../../assets/images/icon/icon-3.png`).default}
                    alt=""
                  />
                  <h3 className="binduz-er-title">Get Every Newsletter</h3>
                  <p>We are not gonna make spamming</p>
                  <div className="binduz-er-input-box">
                    <input type="text" placeholder="Enter your email" />
                    <button type="button">
                      <i className="fal fa-long-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="binduz-er-sidebar-social binduz-er-populer-news-sidebar-add d-none d-lg-block">
                <div className="binduz-er-sidebar-add mt-40">
                  <h3 className="binduz-er-title">
                    Build your website & <span>grow your business</span>
                  </h3>
                  <a className="binduz-er-main-btn" href="#">
                    Purchase
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PopularNewsSection;

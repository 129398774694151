import React, { useEffect } from "react";
import navigationContext from "../../contexts/navigationsContext";
import { navigations } from "../../Data/navigation.json";
import { posts } from "../../Data/posts.json";
import useToggle from "../../Hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import Footer from "../Partials/Footers/HomeTwo";
import HeaderTwo from "../Partials/Headers/HeaderTwo";
import Ads from "./Ads";
import FavoriteCategories from "./FavoriteCategories";
import LatesdNewsSection from "./LatesdNewsSection";
import NewsCategories from "./NewsCategories";
import NewsLetterPartStart from "./NewsLetterPartStart";
import NewsSlider from "./NewsSlider";
import RecentlyViewedSection from "./RecentlyViewedSection";
import TopNewsSection from "./TopNewsSection";

function HomeTwo() {
  const navigationData = navigations;
  useEffect(() => {
    document.querySelector("body").classList.add("gray-bg");
  });
  const latestPosts = posts;
  const recentlyViewedDatas = posts;
  const newCategories = [
    {
      id: `${Math.random()}w2e`,
      name: "Breaking News",
    },
    {
      id: `${Math.random()}w2e`,
      name: "Entertainment",
    },
    {
      id: `${Math.random()}w2e`,
      name: "Sports",
    },
    {
      id: `${Math.random()}w2e`,
      name: "Politics",
    },
    {
      id: `${Math.random()}w2e`,
      name: "Trending",
    },
    {
      id: `${Math.random()}w2e`,
      name: "Football",
    },
    {
      id: `${Math.random()}w2e`,
      name: "Gaming",
    },
  ];
  const [drawer, setDrawer] = useToggle(false);
  return (
    <>
      <Drawer
        drawer={drawer}
        action={setDrawer.toggle}
        navigationData={navigationData}
        drawerPosition="binduz-er-news-offcanvas_menu_left"
      />
      <navigationContext.Provider value={{ navigationData }}>
        <HeaderTwo drawerAction={setDrawer.toggle} />
      </navigationContext.Provider>
      <NewsCategories categories={newCategories} />
      <NewsSlider />
      <TopNewsSection />
      <FavoriteCategories />
      <LatesdNewsSection latestDatas={latestPosts} />
      <RecentlyViewedSection recentlyViewedDatas={recentlyViewedDatas} />
      <NewsLetterPartStart />
      <div className=" container">
        <div className="row">
          <div className=" col-lg-12">
            <Ads />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default HomeTwo;

import React from "react";

function TopNewsFaq() {
  return (
    <>
      <div className="binduz-er-top-news-faq">
        <h4 className="binduz-er-title">
          Illinois House Democrats wrangle over Madigan’s future
        </h4>
        <div className="binduz-er-meta-author">
          <span>
            By <span>Miranda H. Halim</span>
          </span>
        </div>
        <div className="binduz-er-answare">
          <p>Get Ask Amy delivered to your inbox every morning</p>
        </div>
        <div className="binduz-er-client-thumb">
          <img
            src={require(`../../assets/images/client-thumb.png`).default}
            alt=""
          />
        </div>
      </div>
    </>
  );
}

export default TopNewsFaq;

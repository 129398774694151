import React from "react";
import Navigation from "../../../Helpers/Navigation";
import SliderCom from "../../../Helpers/SliderCom";

function MainHeader() {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
  };
  return (
    <header className="binduz-er-header-area binduz-er-header-area-2">
      <div className="binduz-er-header-nav">
        <div className=" container">
          <div className="row">
            <div className=" col-lg-12">
              <div className="binduz-er-header-meddle-bar d-flex justify-content-between">
                <div className="binduz-er-logo">
                  <a href="/">
                    <img
                      src={
                        require(`../../../../assets/images/logo-3.png`).default
                      }
                      alt=""
                    />
                  </a>
                </div>
                <div className="binduz-er-header-add">
                  <img
                    src={
                      require(`../../../../assets/images/space-bg-3.jpg`)
                        .default
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className=" col-lg-12">
              <div className="navigation">
                <nav className="navbar navbar-expand-lg">
                  <div
                    className="collapse navbar-collapse sub-menu-bar"
                    id="navbarSupportedContent"
                  >
                    <Navigation className="mr-auto" />
                  </div>
                  <div className="binduz-er-navbar-btn d-flex">
                    <div className="binduz-er-topbar-headline-2">
                      <SliderCom settings={settings}>
                        <p>
                          <span>
                            <i className="fas fa-bolt"></i> Trending News:
                          </span>
                          <a href="#">Making sense of 2020 through Search</a>
                        </p>
                        <p>
                          <span>
                            <i className="fas fa-bolt"></i> Trending News:
                          </span>
                          <a href="#">2020: The new normals of daily life</a>
                        </p>
                        <p>
                          <span>
                            <i className="fas fa-bolt"></i> Trending News:
                          </span>
                          <a href="#">
                            Miranda halim was viral after finish his workout.
                          </a>
                        </p>
                      </SliderCom>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default MainHeader;

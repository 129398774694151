import React, { useEffect } from "react";
import navigationContext from "../../contexts/navigationsContext";
import { navigations } from "../../Data/navigation.json";
import { posts } from "../../Data/posts.json";
import useToggle from "../../Hooks/useToggle";
import Ads from "../HomeTwo/Ads";
import FavoriteCategories from "../HomeTwo/FavoriteCategories";
import LatesdNewsSection from "../HomeTwo/LatesdNewsSection";
import NewsLetterPartStart from "../HomeTwo/NewsLetterPartStart";
import NewsSlider from "../HomeTwo/NewsSlider";
import RecentlyViewedSection from "../HomeTwo/RecentlyViewedSection";
import TopNewsSection from "../HomeTwo/TopNewsSection";
import Drawer from "../Mobile/Drawer";
import Footer from "../Partials/Footers/HomeTwo";
import HeaderTwo from "../Partials/Headers/HeaderTwo";

function HomeSeven() {
  const navigationData = navigations;
  useEffect(() => {
    document.querySelector("body").classList.add("gray-bg");
  });
  const latestPosts = posts;
  const recentlyViewedDatas = posts;
  const [drawer, setDrawer] = useToggle(false);
  return (
    <>
      <Drawer
        drawer={drawer}
        action={setDrawer.toggle}
        navigationData={navigationData}
        drawerPosition="binduz-er-news-offcanvas_menu_left"
      />
      <navigationContext.Provider value={{ navigationData }}>
        <HeaderTwo drawerAction={setDrawer.toggle} />
      </navigationContext.Provider>
      <LatesdNewsSection className="pt-60" latestDatas={latestPosts} />
      <TopNewsSection />
      <NewsSlider />
      <FavoriteCategories />
      <RecentlyViewedSection recentlyViewedDatas={recentlyViewedDatas} />
      <NewsLetterPartStart />
      <div className=" container">
        <div className="row">
          <div className=" col-lg-12">
            <Ads />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default HomeSeven;

import React, { useEffect } from "react";
import navigationContext from "../../contexts/navigationsContext";
import { navigations } from "../../Data/navigation.json";
import { posts } from "../../Data/posts.json";
import useToggle from "../../Hooks/useToggle";
import Drawer from "../Mobile/Drawer";
import Footer from "../Partials/Footers/HomeFour/Footer";
import HeaderFour from "../Partials/Headers/HeaderFour/index";
import MainContent from "./MainContent";
import TopNewsSection from "./TopNewsSection";
import TrendingNewsSection from "./TrendingNewsSection";

function HomeFour() {
  const navigationData = navigations;
  const trendingNewsDatas = posts;
  const postsForMainContent = posts;
  useEffect(() => {
    document.body.classList.add("gray-bg");
  });
  const [drawer, setDrawer] = useToggle(false);
  return (
    <>
      <Drawer
        drawer={drawer}
        action={setDrawer.toggle}
        navigationData={navigationData}
      />
      <navigationContext.Provider value={{ navigationData }}>
        <HeaderFour drawerAction={setDrawer.toggle}>
          <div className="binduz-er-top-header-ad pt-30 pb-30 d-none d-lg-block">
            <div className=" container">
              <div className="row">
                <div className="col-lg-12">
                  <img
                    src={require(`../../assets/images/space-bg-6.jpg`).default}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </HeaderFour>
      </navigationContext.Provider>
      <TopNewsSection />
      <TrendingNewsSection
        className="pb-40"
        trendingNewsDatas={trendingNewsDatas}
      />
      <MainContent postsForMainContent={postsForMainContent} />
      <Footer />
    </>
  );
}

export default HomeFour;

import React from "react";
import navigationContext from "../../contexts/navigationsContext";
import { navigations } from "../../Data/navigation.json";
import { posts } from "../../Data/posts.json";
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../Helpers/BackToTop";
import EditorPackSection from "../HomeThree/EditorPackSection";
import FeaturePartSection from "../HomeThree/FeaturePartSection";
import Drawer from "../Mobile/Drawer";
import Footer from "../Partials/Footers/HomeThree/index";
import HeaderThree from "../Partials/Headers/HeaderThree";
import PopularNewsSection from "./PopularNewsSection";

function HomeTen() {
  const navigationData = navigations;
  const editorPackSectionDatas = posts;
  const popularNewsData = posts;
  const [drawer, setDrawer] = useToggle(false);
  return (
    <>
      <Drawer
        drawer={drawer}
        action={setDrawer.toggle}
        navigationData={navigationData}
      />
      <navigationContext.Provider value={{ navigationData }}>
        <HeaderThree drawerAction={setDrawer.toggle} />
      </navigationContext.Provider>
      <EditorPackSection
        className="home-ten-hero mt-80"
        editorPackSectionDatas={editorPackSectionDatas}
      />
      <FeaturePartSection />
      <PopularNewsSection popularNewsData={popularNewsData} />
      <Footer />
      <BackToTop />
    </>
  );
}

export default HomeTen;

import React, { useState } from "react";

function TrendingNews() {
  const [tab, setTab] = useState(0);
  const tabHandler = (value) => {
    setTab(value);
  };
  return (
    <div className="binduz-er-video-post-box">
      <div className="binduz-er-trending-video-news-title">
        <h3 className="binduz-er-title">Trending News</h3>
      </div>
      <div className="tab-content" id="pills-tabContent-2">
        <div
          className={`tab-pane fade ${tab === 0 ? "show active" : ""}`}
          id="pills-item-1"
          role="tabpanel"
          aria-labelledby="pills-item-1-tab"
        >
          <div className="binduz-er-trending-video-play-news">
            <div className="binduz-er-top-news-2-item">
              <div className="binduz-er-trending-news-item mb-30">
                <div className="binduz-er-trending-news-overlay">
                  <div className="binduz-er-trending-news-meta">
                    <div className="binduz-er-meta-categories">
                      <a href="#">Technology</a>
                    </div>
                    <div className="binduz-er-meta-date">
                      <span>
                        <i className="fal fa-calendar-alt"></i> 24th February
                        2020
                      </span>
                    </div>
                    <div className="binduz-er-trending-news-title">
                      <h3 className="binduz-er-title">
                        <a href="#">
                          Mountaineering to Maps: Rebecca Moore’s fight for the
                          planet
                        </a>
                      </h3>
                    </div>
                  </div>
                  <div className="binduz-er-trending-play">
                    <a href="#">
                      <i className="fas fa-play"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`tab-pane fade ${tab === 1 ? "show active" : ""}`}
          id="pills-item-2"
          role="tabpanel"
          aria-labelledby="pills-item-2-tab"
        >
          <div className="binduz-er-trending-video-play-news">
            <div className="binduz-er-top-news-2-item">
              <div className="binduz-er-trending-news-item item-2 mb-30">
                <div className="binduz-er-trending-news-overlay">
                  <div className="binduz-er-trending-news-meta">
                    <div className="binduz-er-meta-categories">
                      <a href="#">Technology</a>
                    </div>
                    <div className="binduz-er-meta-date">
                      <span>
                        <i className="fal fa-calendar-alt"></i> 24th February
                        2020
                      </span>
                    </div>
                    <div className="binduz-er-trending-news-title">
                      <h3 className="binduz-er-title">
                        <a href="#">
                          A whale of a tale about responsibility and AI about
                          responsibility
                        </a>
                      </h3>
                    </div>
                  </div>
                  <div className="binduz-er-trending-play">
                    <a href="#">
                      <i className="fas fa-play"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`tab-pane fade ${tab === 2 ? "show active" : ""}`}
          id="pills-item-3"
          role="tabpanel"
          aria-labelledby="pills-item-3-tab"
        >
          <div className="binduz-er-trending-video-play-news">
            <div className="binduz-er-top-news-2-item">
              <div className="binduz-er-trending-news-item item-3 mb-30">
                <div className="binduz-er-trending-news-overlay">
                  <div className="binduz-er-trending-news-meta">
                    <div className="binduz-er-meta-categories">
                      <a href="#">Technology</a>
                    </div>
                    <div className="binduz-er-meta-date">
                      <span>
                        <i className="fal fa-calendar-alt"></i> 24th February
                        2020
                      </span>
                    </div>
                    <div className="binduz-er-trending-news-title">
                      <h3 className="binduz-er-title">
                        <a href="#">
                          A new certification for health insurance advertisers
                          in the U.S.
                        </a>
                      </h3>
                    </div>
                  </div>
                  <div className="binduz-er-trending-play">
                    <a href="#">
                      <i className="fas fa-play"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="binduz-er-trending-video-thumbs">
        <ul className="nav nav-pills" id="pills-tab-trending" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              onClick={() => tabHandler(0)}
              className={`nav-link ${tab === 0 ? "active" : ""}`}
              id="pills-item-1-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-item-1"
              type="button"
              role="tab"
              aria-controls="pills-item-1"
              aria-selected="true"
            >
              <img
                src={
                  require(`../../assets/images/trending-news-item-1.jpg`)
                    .default
                }
                alt=""
              />
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              onClick={() => tabHandler(1)}
              className={`nav-link ${tab === 1 ? "active" : ""}`}
              id="pills-item-2-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-item-2"
              type="button"
              role="tab"
              aria-controls="pills-item-2"
              aria-selected="false"
            >
              <img
                src={
                  require(`../../assets/images/trending-news-item-2.jpg`)
                    .default
                }
                alt=""
              />
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              onClick={() => tabHandler(2)}
              className={`nav-link ${tab === 2 ? "active" : ""}`}
              id="pills-item-3-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-item-3"
              type="button"
              role="tab"
              aria-controls="pills-item-3"
              aria-selected="false"
            >
              <img
                src={
                  require(`../../assets/images/trending-news-item-3.jpg`)
                    .default
                }
                alt=""
              />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default TrendingNews;

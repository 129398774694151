import React from "react";
import Categories from "../../containers/HomeOne/Categories";
import TrendingNews from "../../containers/HomeOne/TrendingNews";

function TrendingSection({ category, className }) {
  return (
    <>
      <section className={`binduz-er-trending-area ${className || ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12">
              <TrendingNews TreandingNewsCategoryTab={category} />
            </div>
            <div className="col-lg-3 col-md-12">
              <Categories TreandingNewsCategoryTab={category} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TrendingSection;

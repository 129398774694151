import React from "react";
import PostCardStyleOne from "../../containers/HomeOne/PostCards/PostCardStyleOne";

function TodayTrending({ todayTrending }) {
  return (
    <>
      <section className="binduz-er-trending-today-area">
        <div className="binduz-er-bg-cover"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="binduz-er-trending-today-topbar">
                <div className="binduz-er-trending-today-title">
                  <h3 className="binduz-er-title">What's trending today</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {todayTrending &&
              todayTrending.length > 0 &&
              todayTrending.slice(0, 4).map((todayTrendingItem, index) => (
                <div
                  key={todayTrendingItem.id + Math.random()}
                  className="col-lg-3 col-md-6"
                >
                  <div className="binduz-er-trending-today-item">
                    <PostCardStyleOne
                      datas={{
                        image:
                          todayTrendingItem.post_images.trending_today[index],
                        category: todayTrendingItem.post_cat[0],
                        date: todayTrendingItem.post_date,
                        title: todayTrendingItem.post_title,
                      }}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default TodayTrending;

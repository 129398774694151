import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import TrendingNewsContext from "../../contexts/HomeOne/TrendingNewsContext";
import dateFormat from "../../lib/dateFormat";

function TrendingNews({ TreandingNewsCategoryTab = [] }) {
  const { trendingNews } = useContext(TrendingNewsContext);
  const [tab, setTab] = useState(TreandingNewsCategoryTab[0].id);
  const tabHandler = (e, value) => {
    e.preventDefault();
    setTab(value);
  };
  return (
    <>
      <div className="binduz-er-trending-news-topbar d-block d-md-flex justify-content-between align-items-center">
        <div className="binduz-er-trending-box">
          <div className="binduz-er-title">
            <h3 className="binduz-er-title">Trending News</h3>
          </div>
        </div>

        <div className="binduz-er-news-tab-btn d-flex justify-content-md-end justify-content-start">
          <ul className="nav nav-pills " id="pills-tab" role="tablist">
            {TreandingNewsCategoryTab &&
              TreandingNewsCategoryTab.length > 0 &&
              TreandingNewsCategoryTab.map((item) => (
                <li
                  key={item.id + Math.random()}
                  className="nav-item"
                  role="presentation"
                >
                  <a
                    className={`nav-link ${tab === item.id ? "active" : ""}`}
                    onClick={(e) => tabHandler(e, item.id)}
                    id="pills-1-tab"
                    data-bs-toggle="pill"
                    href="#"
                    role="tab"
                    aria-controls="pills-1"
                    aria-selected="true"
                  >
                    {item.name}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="binduz-er-trending-news-list">
            <div className="tab-content mt-50" id="pills-tabContent">
              {TreandingNewsCategoryTab &&
                TreandingNewsCategoryTab.length > 0 &&
                TreandingNewsCategoryTab.map((item) => (
                  <div
                    key={item.id + Math.random()}
                    className={`tab-pane fade ${
                      item.id === tab ? "show active" : ""
                    }`}
                    id="pills-1"
                    role="tabpanel"
                    aria-labelledby="pills-1-tab"
                  >
                    <div className="row">
                      <div className="col-lg-7 col-md-6">
                        <div className="binduz-er-trending-box">
                          <div className="binduz-er-trending-news-item">
                            <img
                              src={
                                require(`../../assets/images/${trendingNews[0].post_images.trending_news_list[0]}`)
                                  .default
                              }
                              alt=""
                            />
                            <div className="binduz-er-trending-news-overlay">
                              <div className="binduz-er-trending-news-meta">
                                <div className="binduz-er-meta-categories">
                                  <a href="#">{trendingNews[0].post_cat[0]}</a>
                                </div>
                                <div className="binduz-er-meta-date">
                                  <span>
                                    <i className="fal fa-calendar-alt"></i>
                                    <span className="binduz-formated-date">
                                      {dateFormat(trendingNews[0].post_date)}
                                    </span>
                                  </span>
                                </div>
                                <div className="binduz-er-trending-news-title">
                                  <h3 className="binduz-er-title">
                                    <Link to="/blog-details-two">
                                      {trendingNews[0].post_title}
                                    </Link>
                                  </h3>
                                </div>
                              </div>
                              <div className="binduz-er-news-share">
                                <a href="#">
                                  <i className="fal fa-share"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-6">
                        <div className="binduz-er-trending-news-list-item">
                          {trendingNews &&
                            trendingNews.length > 0 &&
                            trendingNews.slice(1, 4).map((postItem, index) => (
                              <div
                                key={postItem.id}
                                className="binduz-er-trending-news-list-box"
                              >
                                <div className="binduz-er-thumb">
                                  <img
                                    src={
                                      require(`../../assets/images/${postItem.post_images.trending_news_list[index]}`)
                                        .default
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="binduz-er-content">
                                  <div className="binduz-er-meta-item">
                                    <div className="binduz-er-meta-categories">
                                      <a href="#">{postItem.post_cat[0]}</a>
                                    </div>
                                    <div className="binduz-er-meta-date">
                                      <span>
                                        <i className="fal fa-calendar-alt"></i>
                                        {dateFormat(postItem.post_date)}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="binduz-er-trending-news-list-title">
                                    <h4 className="binduz-er-title line-clump-2">
                                      <Link to="/blog-details-two">
                                        {postItem.post_title}
                                      </Link>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TrendingNews;

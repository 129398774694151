import React from "react";
import CardRowStyleHomeTwo from "../../containers/HomeTwo/Cards/CardRowStyleHomeTwo";
import CardStyleHomeTwo from "../../containers/HomeTwo/Cards/CardStyleHomeTwo";

function LatesdNewsSection({ latestDatas = [], className }) {
  return (
    <section className={`binduz-er-latest-news-area ${className || ""}`}>
      <div className=" container">
        <div className="row">
          <div className=" col-lg-8">
            <div className="binduz-er-top-news-title">
              <h3 className="binduz-er-title">Latest News</h3>
            </div>
            {latestDatas &&
              latestDatas.length > 0 &&
              latestDatas.slice(0, 4).map((latestItem, index) => (
                <CardRowStyleHomeTwo
                  key={latestItem.id}
                  datas={{
                    image: latestItem.post_images.latest_news[index],
                    category: latestItem.post_cat[0],
                    title: latestItem.post_title,
                    date: latestItem.post_date,
                  }}
                />
              ))}
          </div>
          <div className=" col-lg-4">
            <div className="binduz-er-top-news-title">
              <h3 className="binduz-er-title">Video Post</h3>
            </div>

            <div className="binduz-er-video-post">
              {latestDatas &&
                latestDatas.length > 0 &&
                latestDatas.slice(0, 2).map((latestItem, index) => (
                  <CardStyleHomeTwo
                    key={latestItem.id}
                    datas={{
                      image: latestItem.post_images.latest_news[index],
                      category: latestItem.post_cat[0],
                      title: latestItem.post_title,
                      date: latestItem.post_date,
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LatesdNewsSection;

import React from "react";
import SingleSlider from "../../containers/HomeFive/SingleSlider";
import SocialList from "../../containers/HomeTwo/SocialList";

function FeatureSection() {
  return (
    <section className="binduz-er-featured-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <SingleSlider title="Technology" />
          </div>
          <div className="col-lg-4 col-md-6">
            <SingleSlider title="Lifestyle" />
          </div>
          <div className="col-lg-4">
            <div className="binduz-er-sidebar-social">
              <div className="binduz-er-sidebar-title">
                <h4 className="binduz-er-title">Social Feed</h4>
              </div>
              <div className="binduz-er-social-list">
                <SocialList />
              </div>
              <div className="binduz-er-sidebar-add mt-20">
                <h3 className="binduz-er-title">
                  Build your website & <span>grow your business</span>
                </h3>
                <a className="binduz-er-main-btn" href="#">
                  Purchase
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureSection;

import React, { useState } from "react";
import SliderCom from "../Helpers/SliderCom";

function NewsSlider() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const SliderForImage = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    fade: true,
  };
  const SliderForArticle = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    centerMode: true,
    arrows: false,
    centerPadding: "0",
    focusOnSelect: true,
  };
  const prevHandler = () => {
    nav1.slickPrev();
  };
  const nextHandler = () => {
    nav1.slickNext();
  };
  return (
    <div className="binduz-er-news-slider-area pt-30 pb-60">
      <div className=" container">
        <div className="binduz-er-news-slider-box">
          <div className="row g-0 align-items-center">
            <div className=" col-lg-6">
              <div className="binduz-er-news-slider-item">
                <span
                  onClick={prevHandler}
                  className="prev slick-arrow"
                  style={{ display: "block" }}
                >
                  <i className="fal fa-long-arrow-left"></i>
                </span>
                <SliderCom
                  settings={SliderForImage}
                  asNavFor={nav2}
                  selector={(slider1) => setNav1(slider1)}
                >
                  <div className="binduz-er-item">
                    <img
                      src={
                        require(`../../assets/images/news-slider-1.jpg`).default
                      }
                      alt=""
                    />
                  </div>
                  <div className="binduz-er-item">
                    <img
                      src={
                        require(`../../assets/images/news-slider-2.jpg`).default
                      }
                      alt=""
                    />
                  </div>
                </SliderCom>
                <span
                  onClick={nextHandler}
                  className="next slick-arrow"
                  style={{ display: "block" }}
                >
                  <i className="fal fa-long-arrow-right"></i>
                </span>
              </div>
            </div>
            <div className=" col-lg-6">
              <div className="binduz-er-news-slider-content-slider">
                <SliderCom
                  settings={SliderForArticle}
                  asNavFor={nav1}
                  selector={(slider2) => setNav2(slider2)}
                >
                  <div className="binduz-er-item">
                    <div className="binduz-er-meta-item">
                      <div className="binduz-er-meta-categories">
                        <a href="#">Technology</a>
                      </div>
                      <div className="binduz-er-meta-date">
                        <span>
                          <i className="fal fa-calendar-alt"></i> 24th February
                          2020
                        </span>
                      </div>
                    </div>
                    <div className="binduz-er-news-slider-content">
                      <h3 className="binduz-er-title">
                        <a href="#">
                          Spot misinformation online with these tips
                        </a>
                      </h3>
                      <p>
                        How effectively the massive shopping center can
                        repurpose more than 300,000 square feet soon to be
                        vacated
                      </p>
                    </div>
                    <div className="binduz-er-meta-author">
                      <div className="binduz-er-author">
                        <img
                          src={
                            require(`../../assets/images/user-2.jpg`).default
                          }
                          alt=""
                        />
                        <span>
                          By <span>Rosalina D.</span>
                        </span>
                      </div>
                      <div className="binduz-er-meta-list">
                        <ul>
                          <li>
                            <i className="fal fa-eye"></i> 5k
                          </li>
                          <li>
                            <i className="fal fa-heart"></i> 5k
                          </li>
                          <li>
                            <i className="fal fa-comments"></i> 5k
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="binduz-er-item">
                    <div className="binduz-er-meta-item">
                      <div className="binduz-er-meta-categories">
                        <a href="#">Technology</a>
                      </div>
                      <div className="binduz-er-meta-date">
                        <span>
                          <i className="fal fa-calendar-alt"></i> 24th February
                          2020
                        </span>
                      </div>
                    </div>
                    <div className="binduz-er-news-slider-content">
                      <h3 className="binduz-er-title">
                        <a href="#">Take a look at these pandemic pastimes</a>
                      </h3>
                      <p>
                        How effectively the massive shopping center can
                        repurpose more than 300,000 square feet soon to be
                        vacated
                      </p>
                    </div>
                    <div className="binduz-er-meta-author">
                      <div className="binduz-er-author">
                        <img
                          src={
                            require(`../../assets/images/user-2.jpg`).default
                          }
                          alt=""
                        />
                        <span>
                          By <span>Rosalina D.</span>
                        </span>
                      </div>
                      <div className="binduz-er-meta-list">
                        <ul>
                          <li>
                            <i className="fal fa-eye"></i> 5k
                          </li>
                          <li>
                            <i className="fal fa-heart"></i> 5k
                          </li>
                          <li>
                            <i className="fal fa-comments"></i> 5k
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </SliderCom>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsSlider;
